import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ResponsiveMode } from '../../../enum/responsiveModes';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { SharedService } from '../../../_services/shared.service';
import Utils from '../../../_utils/utils';
import { Offer } from '../../../model/offer';
import { NgOptimizedImage } from '@angular/common'

@Component({
  selector: 'app-offer-image',
  standalone: true,
  imports: [CommonModule, NgOptimizedImage],
  templateUrl: './offer-image.component.html',
  styleUrl: './offer-image.component.css'
})
export class OfferImageComponent implements OnInit {


  @Input()
  offer!: Offer | null;
  @Input()
  index: number = 0;

  viewMode: string = ResponsiveMode.horizontal;

  constructor(private responsive: BreakpointObserver,
              public sharedService: SharedService) {

  }

  ngOnInit(): void {

    this.responsive.observe([
      Breakpoints.HandsetPortrait,
    ])
      .subscribe(result => {

        const breakpoints = result.breakpoints;

        if(breakpoints[Breakpoints.HandsetPortrait]){
          this.viewMode = ResponsiveMode.vertical;
        }else {
          this.viewMode = ResponsiveMode.horizontal;
        }          

      });
  }

  responsiveStyle(styleObj: {[key: string]: string}): {[key: string]: boolean} {   
    return Utils.responsiveStyle(this.viewMode, styleObj)
  } 


}
