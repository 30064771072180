
<div 
    class="container"
    style="width: 100vw; height: 100vh; ">

    <div  
        style="
        margin: auto;
        height: 37%;
        width: 40%;
        padding: 10px;
        
        ">
    </div>


            <mat-card
            [ngStyle]="this.sharedService.customStyle('cardTheme')"
            style="

            /* vertical center */
            margin: 0;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            /* vertical center */ 

            margin: auto;
            padding-left: 50px;
            padding-right: 50px;
            padding-bottom: 50px;
            padding-top: 50px;

            width: 60%;
            min-width: 380px !important;
            height: auto;
            max-height: 221 !important;
           
            
            text-align: center;"
            class="not-available-text"            
            >
            <p [ngStyle]="this.sharedService.customStyle('txtTheme')">
                Apologies, it appears this offer is no longer available. Please feel free to ask a hotel team member about upgrades upon check-in.
            </p>
            </mat-card>


</div>



