
<ng-template #termsAndConditions >
    <app-offer-photo-terms [offer]="this.sharedService.offer" [modalRef]="modalRef"></app-offer-photo-terms>
</ng-template>
<div (click)="toggleCollapse(index)"
    *ngIf="offer" style="display: flex; flex-direction: column; overflow: hidden;" [ngClass]="this.responsiveStyle({ 'vertical': 'container card-content-group-handset', 'horizontal': 'card-content-group-desktop'})">
    <div #cardContent  [ngStyle]="{'width': viewMode===responsiveMode.horizontal ? '80%' : '100%'}">
        <div [ngStyle]="{'margin-top': viewMode === responsiveMode.horizontal ? '30px' : '10px' }"></div>
        <div [ngStyle]="{'width': viewMode===responsiveMode.horizontal ? '80%' : '100%'}">
            <div class="glow" style="max-width: inherit; overflow: hidden; display: inline-flex;" 
                *ngIf="this.sharedService.currentIndex === index && !this.collapsed">
                <li></li>  
                <p  [ngClass]="this.responsiveStyle({ 'vertical': 'card-content-paragraph-handset', 'horizontal': 'card-content-paragraph-desktop'})"
                    [ngStyle]="this.sharedService.customStyle('cardContentTheme')">
                    {{offer.amenities.summary}}
                </p> 
            </div>     
            <div class="card-content-handset glow" style="max-width: inherit; overflow: hidden;" [ngClass]="contentStyle(index, j)"
                [ngStyle]="this.sharedService.customStyle('cardContentTheme')"                                   
                *ngFor="let attr of this.tapForDetails(index, offer);let j = index">               
                <li [ngStyle]="{'color': attr==='' ? 'transparent' : 'inherit' }" ></li> <!-- parent height is auto, button position is depedent on 3 elements present, tapForDetails() adds additional attrs to insure 3 elements, ngIf hides the additional three bullets elements -->                           
                <p> 
                    <span [ngStyle]="this.sharedService.customStyleMulti(['priceTheme'])" >{{attr.substring(0, attr.indexOf(':'))}}</span>                                        
                    <span [ngStyle]="this.sharedService.customStyleMulti(['barPriceTheme'])">{{attr.substring(attr.indexOf(':'), attr.length)}}</span>
                </p>                                
            </div>                      
        </div>                    
        <div [hidden]="viewMode!=responsiveMode.horizontal" style="height: 17px;"></div>         
    </div>
    <div [ngClass]="this.responsiveStyle({ 'vertical': 'offer-actions-handset', 'horizontal': 'offer-actions-desktop'})">        
        <button class="button-request" #cardButton [ngClass]="this.responsiveStyle({ 'vertical': 'card-action-handset', 'horizontal': 'card-action-desktop'})"                                            
                [ngStyle]="this.sharedService.customStyle('buttonRequestTheme')"
                (click)="openModalTermsAndConditions(termsAndConditions, index)">UPGRADE
        </button>       
        <div class="card-header-price-handset card-title-price-handset" style="width: 100%; display: flex;"                           
            *ngIf="viewMode!=responsiveMode.horizontal">
            <div [ngStyle]="this.sharedService.customStyle('priceTheme')" style="margin-left: auto; margin-right: -140px; float: right; ">{{this.offerIndex(this.index, this.viewMoreToggle, this.offers!.length)}}</div>
            <p style="margin-left: auto; margin-right: 0px; float: right; width: 140px;" >
                <span [ngStyle]="this.sharedService.customStyle('barPriceTheme')" style="text-decoration: line-through; ; font-size: small;">{{offer.bar_price | currency:'USD' : 'symbol' : '1.0-0'}}</span> 
                <span [ngStyle]="this.sharedService.customStyle('priceTheme')"> {{offer.ofr_price | currency:'USD' : 'symbol' : '1.0-0'}}/nt</span>
            </p>                        
        </div>              
    </div>                                                                                             
    <div [hidden]="viewMode!=responsiveMode.horizontal" style="height: 25px;"></div>
</div>