<ngx-spinner [ngStyle]="this.sharedService.customStyle('spinnerTheme')" type="square-jelly-box"><h3 [ngStyle]="this.sharedService.customStyle('spinnerTheme')">Loading...</h3></ngx-spinner>
<!-- *ngIf="{offerConfirmation: offerConfirmation$ | async, error: offerErrors$ | async} as data" -->
<div [ngStyle]="this.sharedService.customStyle('bodyTheme')" style="height: auto;">                        
    <!-- [hidden]="data.error ? true : false" -->
    <div>
        <!-- *ngIf="data.offerConfirmation" -->
        <div style="width: 100vw; height: 100vh;">
            <mat-card [ngStyle]="this.sharedService.customStyle('cardTheme')"
                style="                    
                    /* vertical center */
                    margin: 0;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    /* vertical center */

                    padding-left: 50px;
                    padding-right: 50px;
                    padding-bottom: 50px;
                    padding-top: 50px;

                    width: 25%;
                    min-width: 380px !important;
                    height: auto;
                    max-height: 221 !important;
                    font-size: large;
                    text-align: center;"

                    class="not-available-text"   
                >
                <p [ngStyle]="this.sharedService.customStyle('cardTheme')">Please enter the 6 digit code sent to your email.</p>        

                <code-input [isCodeHidden]="true"
                            [codeLength]="6"
                            (codeChanged)="onCodeChanged($event)"
                            (codeCompleted)="onCodeCompleted($event)"
                            [ngStyle]="this.sharedService.customStyle('cardTheme')"
                            >
                </code-input>
                <br>
                <p *ngIf="this.invalidCode" style="color: red;">*The code you entered was invalid.</p>        

            </mat-card>
        </div>
    </div>
    <!-- <app-offer-not-available [hidden]="data.error ? false : true"></app-offer-not-available>                     -->
</div> 
