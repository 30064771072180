
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">

<ngx-spinner type="square-jelly-box">
    <h3>Loading...</h3>
</ngx-spinner>

<ng-template #template>

    <div class="modal-header" style=" border: none !important; background-color:  #161616 !important; height: 40px !important; ">
    
        <p class="modal-room_title" *ngIf="this.offers">{{this.selected_offer.amenities.upsell_room_name}}</p>
        <div class="button-close" (click)="modalRef.hide()">
            <img  src = "assets\svg\close-70.svg" class="image-close"/>
        </div>
    </div>

   <gallery style="width: 100%; background-color: #161616;"
        [items]="images">
   </gallery>   

   <div class="modal-footer" style="background-color: #161616;">
    
    <div style="display: inline-flex; width: 100%;">
        <div class="card-title-price" style="margin-top: auto; margin-bottom: auto; display: inline-flex; width: 85%; color: white; font-size: 20px; float: right">
            <span class="modal-bar-price">${{this.selected_offer.bar_price}}</span>&nbsp;<span> ${{this.selected_offer.ofr_price}}/nt</span>
        </div>
        <button type="button" class="button-request" style=" margin-left: auto; margin-right: 0; text-shadow: 0 0 0px #ffffff; width: 12%;"
        (click)="_openModalTermsAndConditions(termsAndConditions)">REQUEST</button>
    </div>
   </div>
</ng-template>


<ng-template #termsAndConditions >

    <div >
        <div style="background-color:  #161616 !important; border-radius: 7px;">

            <div class="modal-header" style="color: white; border: none !important; background-color:  #161616 !important; height: 40px !important; ">
                <p></p>
                <div class="button-close" (click)="modalRef.hide()">
                    <img  src = "assets\svg\close-70.svg" class="image-close"/>
                </div>
            </div>
   

            <!-- class="modal-body" -->

            <div style="width: 90%; height: 500px; margin-left: auto; margin-right: auto;">
                <div                  
                style="height: 90%; width: 100%; 
                overflow-y: scroll;
                scrollbar-color: #5a5959 #2d2d2d;
                scrollbar-width: thin;
                margin-top: 50px;
                background-color: #161616; color: white;                
                "
                >
                <div style="width: 95%;">
                    <p class="terms-txt"> Thank you for your request.</p>
                    <p>Your pre tax cost per stay night is {{this.selected_offer.ofr_price | currency:'USD' }} for {{this.selected_offer.los}} nights for a total of {{this.selected_offer.los * this.selected_offer.ofr_price | currency:'USD' }}.</p>
                    <p class="terms-txt"> Upon submitting this request, the property team will typically review and accept or decline the request within 24 hours and you will be notified via an email communication. 
                    If declined, this is typically due to extenuating inventory circumstances preventing the upgrade at this time. 
                    Please note that accepted upgrade requests are non-refundable.!</p>
                    <br>
                    <h1 class="terms-txt" style="color: white;">Terms & Conditions</h1>                     
                    <div class="modal-dialog" style="background-color:  #161616 !important; height: 100%; border: none;">
                        <p class="terms-txt">Our Terms and Conditions were last updated on 1/1/2024.</p>
                        <p class="terms-txt">Please read these terms and conditions carefully before using Our Service.</p>
        
                        <h2 class="terms-txt" style="font-size: 24 px !important;">Acknowledgment</h2>
                        <p class="terms-txt"></p>
                        <p class="terms-txt">These are the Terms and Conditions governing the use of this Service and the agreement that operates
                            between You and the Company. These Terms and Conditions set out the rights and obligations of all users
                            regarding the use of the Service.
                        </p>
                        <p></p>            
                        <p class="terms-txt" >Your access to and use of the Service is conditioned on Your acceptance of and compliance with these Terms and Conditions. These Terms and Conditions apply to all visitors, users and others who access or use the Service.</p>
                        <p></p>
                        <p class="terms-txt" >By accessing or using the Service You agree to be bound by these Terms and Conditions. If You disagree with any part of these Terms and Conditions then You may not access the Service.</p>
                        <p></p>
                        <p class="terms-txt" >You represent that you are over the age of 18. The Company does not permit those under 18 to use the Service.</p>
                        <p></p>
                        <p class="terms-txt" >Your access to and use of the Service is also conditioned on Your acceptance of and compliance with the Privacy Policy of the Company. Our Privacy Policy describes Our policies and procedures on the collection,
                            use and disclosure of Your personal information when You use the Application or the Website and tells You about Your privacy rights and how the law protects You. Please read Our Privacy Policy carefully before using Our Service.</p>
                        <p></p>
                        <h2 class="terms-txt" style="font-size: 24 !important;">User Accounts</h2>
                        <p></p>            
                        <p class="terms-txt" >When You create an account with Us, You must provide Us information that is accurate, complete, and
                            current at all times. Failure to do so constitutes a breach of the Terms, which may result in immediate
                            termination of Your account on Our Service.
                            </p>
                        <p></p>
                        <p class="terms-txt" >You are responsible for safeguarding the password that You use to access the Service and for any activities
                            or actions under Your password, whether Your password is with Our Service or a Third-Party Social Media
                            Service.
                            </p>
                        <p></p>
                        <p class="terms-txt" >You agree not to disclose Your password to any third party. You must notify Us immediately upon becoming
                            aware of any breach of security or unauthorized use of Your account.</p>
                        <p></p>
                        <p class="terms-txt" >You may not use as a username the name of another person or entity or that is not lawfully available for
                            use, a name or trademark that is subject to any rights of another person or entity other than You without
                            appropriate authorization, or a name that is otherwise offensive, vulgar or obscene.
                            </p>
                        <p></p>
                    </div>                
                
                
                </div>
                
                </div>
            </div>
            
     
        
           <div class="modal-footer" style="background-color: #161616;">
            
            <div style="display: inline-flex; width: 100%;">
                <label style="margin-left: auto; margin-right: 0; right: float; color: white;">Acknowledgment
                    <!-- <span style="width: 5px;"></span> -->
                    
                    <input type="checkbox" style="margin-top: 12px; position: absolute;">
                    &nbsp;
                    <span class="checkmark" style="margin-bottom: -2px !important;"></span>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <button data-dismiss="modal" type="button" class="button-request" style=" margin-left: auto; margin-right: 0; text-shadow: 0 0 0px #ffffff; width: 100px;"
                    


                    (click)="this.acceptOffer(modalRef)">ACCEPT</button>
                </label>
    
            </div>
           </div>
            
        </div>
    </div>


</ng-template>



<div style="height: 100%;">

    <mat-card *ngIf="offers" class="offer-card" [style]="'height: 205px;'">
        <mat-card-content  *ngIf="offers.length > 0"
            style="color: rgba(255, 255, 255, 0.90); 
                margin-right: auto; 
                margin-left: 10px;
                font-family: Verdana, Geneva, Tahoma, sans-serif;">
            <p class="greeting-txt">Hi {{offers![0].guest_fname == null ? '' : offers![0].guest_fname}}!</p>
            <div style="display: flex;" >
                <p class="greeting-txt">You are currently booked at the {{offers![0].prop_name == null ? '' : offers![0].prop_name}} into a 
                    <span style="color: white;  text-transform: uppercase; ">{{ offers![0].base_room_category_name == null ? '' : offers![0].base_room_category_name}}</span> on {{offers![0].dt_arrival == null ? '' : offers![0].dt_arrival | date:'fullDate'}} under confirmation number {{offers![0].conf_num  == null ? '' : offers![0].conf_num}}.</p>
            </div>
            <p></p>
            <p class="greeting-txt" >We have some premium rooms available over these dates and would like to extend this personal upgrade offer to you. Reserve now for no upfront charge while rooms last!</p>
        </mat-card-content>    
    </mat-card>
    
    <form class="example-form" [hidden]="!view_more_toggle || !offers">
        <mat-form-field style="height: 70px;" class="example-full-width mat-white " >
          <mat-label>Offer Search</mat-label>
          <input 
          style="margin-bottom: 30px;"
          #searchInput
          matInput 
          placeholder="Offer..." 
          value="">
        </mat-form-field>
    </form>

    <mat-card 
        class="offer-card"  
        [ngStyle]="{'transition: all 0.5s;': !collapsed && this.currentIndex == i}"
        [style.height] = "!collapsed && this.currentIndex == i ? cardHeight.toString()+'px !important': MIN_CARD_HEGIHT.toString()+'px !important'"
        *ngFor="let offer of offers?.slice(0, this.numberOfOffersToShow);let i = index">
        
        <div style="display: flex;" >
            <!--  -->
            <div class="box"  (click)="openModal(template, i)" [ngClass]="{'image-shake': offer.is_hot_deal}">


                <div
                class="image-hotel-background"                    
                [ngStyle]="{'background-image': 'url(' + offer.amenities.room_photo_url + ')'}">
                    <div
                        mat-card-image
                        style="
                        background-color: rgba(255, 255, 255, 0.05) !important;
                        -webkit-backdrop-filter: blur(8px) !important;
                        backdrop-filter: blur(8px) !important;
                        height: 100%;                         
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: cover;">                        
                    </div>
                </div>

                <div>
                    <img 
                    mat-card-image         
                    class="image-hotel" 
                    [src]="offer.amenities.room_photo_url">
        
                    <div class="ribbon-red"  [hidden]="!offer.is_hot_deal">
                        <div class="txt-red">
                            Hot Deal!
                        </div>
                    </div>

                    <div class="ribbon-blue"  [hidden]="!offer.is_popular">
                        <div class="txt-blue">
                            Popular!
                        </div>
                    </div>                    

                </div>


            </div>

            <div style="display: grid; margin-left: auto; margin-right: 0; width: 100%;">
                <div style="
                            display: inline-flex; 
                            margin-left: 25px;
                            background-color: #2d2d2d;
                            border-radius: 0px 5px 0px 0px;
                            box-sizing: border-box;
                            height: 48px;
                            ">                    
                    <mat-card-header class="card-header container">
                            <mat-card-title class="card-title">{{offer.amenities.upsell_room_name}}</mat-card-title>                                    
                    </mat-card-header>
    
                    <mat-card-header class="card-header card-header-price">
                            <p class="card-title card-title-price " style="font-weight: bold; margin-top: -3px;">
                                <span style="text-decoration: line-through; color: rgba(255, 255, 255, 0.60); font-size: 14px !important; font-weight: normal;" >${{offer.bar_price}}</span> ${{offer.ofr_price}}/nt
                            </p>
                    </mat-card-header>                
                </div>
    

                <mat-card-content style="display: flex; margin-top: 15px; margin-left: 55px;">                                    
                    <!-- <div style="display:grid; width: 110px; " *ngIf="offer.amenities.icons.is_king_room">
                        <img  src = "assets\svg\hotel-bed-outline.svg" alt="King Bed" class="icon-hotel"
                              style="margin: auto; display: block;"/> 
                        <p style="color: white; text-align: center; margin-top: 3px;">King Room</p>                   
                    </div>

                    <div style="display:grid; width: 110px; ;" *ngIf="offer.amenities.icons.is_view">
                        <img  src = "assets\svg\binoculars.svg" alt="Nice View" class="icon-hotel"
                              style="margin: auto; display: block;"/> 
                        <p style="color: white; text-align: center; margin-top: 3px;">View</p>                    
                    </div>

                    <div style="display:grid; width: 110px; " *ngIf="offer.amenities.icons.is_non_smoking">
                        <img  src = "assets\svg\non-smoking.svg" alt="Non Smoking" class="icon-hotel"
                              style="margin: auto; display: block;"/> 
                        <p style="color: white; text-align: center; margin-top: 3px;">Non Smoking</p>                    
                    </div>

                    <div style="display:grid; width: 110px; " *ngIf="offer.amenities.icons.is_large_room">
                        <img  src = "assets\svg\triangle-ruler.svg" alt="Large Room" class="icon-hotel"
                              style="  margin: auto; display: block; margin-top: 2px;"/> 
                        <p style="color: white; text-align: center; margin-top: 3px;">{{offer.amenities.sqft}} Sq Ft</p>                    
                    </div>

                    <div style="display:grid; width: 110px; " *ngIf="offer.amenities.icons.is_renovated">
                        <img  src = "assets\svg\star.svg" alt="Large Room" class="icon-hotel"
                              style="  margin: auto; display: block;"/> 
                        <p style="color: white; text-align: center; margin-top: 3px;">Renovated</p>                    
                    </div>     -->
                </mat-card-content>


                <div style="display: inline-flex; margin-top: -18px; margin-left: 15px; cursor: pointer; ">
                    
                    <div [attr.id]="'contentContainer' + i"   
                        class="card-content-contracted glow container"
                        [ngClass]="{'card-content-expanded': (this.currentIndex == i && !this.collapsed)}"
                        (click)="toggleCollapse(i)">

                        <!-- 
                            *ngIf="(this.currentIndex == i && !this.collapsed)"
                            [hidden]="(this.currentIndex != i && this.collapsed)"                        
                        -->
                        <div style="height: 20px;"></div>
                        <mat-card-content 
                            [attr.id]="'contentParagraph' + i"                         
                            class="card-content-paragraph hidden"
                            [ngClass]="this.hiddenStyle(i)"
                        >
                            {{offer.amenities.upsell_room_description}}
                        </mat-card-content>
                        
                        <mat-card-content 
                            [attr.id]="'contentLineHeight' + i"                         
                            class="card-content hidden"
                            >
                        </mat-card-content>


                            <mat-card-content 

                            class="card-content " 
                            *ngFor="let attr of offer.amenities.upsell_attributes.slice(0, this.max_list_index);let j = index"                            
                            [ngClass]="{
                                'even-more-opacity': (j == 0 && this.currentIndex !== i) || (j == 0 && this.currentIndex === i && this.collapsed), 
                                'more-opacity': (j == 1 && this.currentIndex !== i) || (j == 1 && this.currentIndex === i && this.collapsed), 
                                'some-opacity': (j == 2  && this.currentIndex !== i) || (j == 2 && this.currentIndex === i && this.collapsed),
                                'card-details-txt' : (j == 3  && this.currentIndex !== i) || (j == 3 && this.currentIndex === i && this.collapsed)
                                }">

                            <!-- <ng-container *ngIf="document.getElementById('content-' + i.toString() + '-' + i)"> -->
                                 
                                <p *ngIf="this.togglAttrSpan(j - 1, i, attr)" 
                                style="color: rgba(255, 255, 255, 0.60); display: list-item;"> 
                                
                                <span  *ngIf="this.togglAttrSpan(j + 1, i, attr)" style="color: white !important;">
                                {{attr.substring(0, attr.indexOf(':'))}}
                            </span>
                            
                            {{clickForMoreDetails(j, i, attr)}}</p>
                            
                        
                            <!-- </ng-container>                     -->

                                
                    
                        </mat-card-content>



                    </div>



                    <!-- class="button-request-contracted"
                    [ngClass]="{'button-request-expanded': !collapsed && this.currentIndex == i}" -->
                    <mat-card-actions                        
                        class="offer-actions" 
                        
                        style="margin-left: auto; margin-right: 0; "


                        >                                
                        
                            <button 
                            class="button-request" 
                            style="width: 120px;  
                            transition: all 0.5s; 
                            margin-right: 5px; 
                            margin-top: 0; 
                            margin-bottom: auto; 
                            float: bottom;"
                            [style.margin-top] = "!collapsed && this.currentIndex == i ? requestButtonMargin.toString()+'px !important': MIN_REQ_BTN_MARGIN.toString()+'px'"
                            mat-raised-button (click)="openModalTermsAndConditions(termsAndConditions, i)">REQUEST</button>
                        
                    </mat-card-actions>
                </div>                
            </div>
        </div>
    </mat-card>
    
</div>


<div [hidden]="!offers" 
    style="
    width: 1140px;
    min-width: 1050px;
    max-width: 1140px;
    display: flex;
    margin: auto;
    margin-top: 15px;
    text-align: center;">
    <p style="
        text-align: left; 
        text-transform: uppercase; 
        color: white;
        display: flex;
        margin-left: auto; 
        margin-right: 0;">
        <span 
            style="color: white;" 
            class="button-link"
            (click)="this.declineOffer()"

            >NO THANKS
        </span>
        <span style="color: white;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> 
        <span style="color: white;" class="button-link" (click)="toggleSearch()">VIEW MORE &nbsp;<i class="fa fa-plus" style="color: #77b300"></i> </span>
    </p>
</div>


<!-- [style.height] = "!collapsed && this.currentIndex == i ? cardHeight.toString()+'px !important': MIN_CARD_HEGIHT.toString()+'px !important'" -->
<mat-card 
class="offer-card hidden"  
[ngStyle]="{'transition: all 0.5s;': !collapsed && this.currentIndex == i}"

style="height: auto !important;"
*ngFor="let offer of offers?.slice(0, this.numberOfOffersToShow);let i = index">


    <div class=""  [attr.id]="'content-' + i" style="height: auto;"  >
        <mat-card-content 
        *ngFor="let attr of offer.amenities.upsell_attributes.slice(0, offer.amenities.upsell_attributes.length);let j = index"      
        class="card-content "            
        [attr.id]="'content-' + i + '-' + j"                            
>                            
    <p style="color: rgba(255, 255, 255, 0.60)"> <span style="color: white !important;">{{attr.substring(0, attr.indexOf(':'))}}</span>
    {{attr.substring(attr.indexOf(':'), attr.length) }}</p>
    </mat-card-content>

    </div>

</mat-card>

<!-- <mat-expansion-panel hideToggle>
    <mat-expansion-panel-header>
      <mat-panel-title>
        This is the expansion title
      </mat-panel-title>
      <mat-panel-description>
        This is a summary of the content
      </mat-panel-description>
    </mat-expansion-panel-header>
    <p>This is the primary content of the panel.</p>
  </mat-expansion-panel>
     -->