<div class="backdrop-blur" (click)="this.modalRef.hide()"></div>
<div *ngIf="offer">
    <div class="modal-header" style=" border: none !important; height: 40px !important;" [ngStyle]="this.sharedService.customStyleMulti(['bodyModalTheme', 'txtModalTheme'])">
        <p class="modal-room-title" *ngIf="offer" [ngStyle]="this.sharedService.customStyleMulti(['txtModalTheme', 'bodyModalTheme', ])">{{offer.amenities.upsell_room_name}}</p>
        <div class="button-close" (click)="modalRef.hide()">
            <img  src='{{ "assets/svg/close-70" + this.sharedService.iconColorClose + ".svg" }}' class="image-close"/>
        </div>
    </div>
    <gallery style="width: 100%;" [items]="images" [ngStyle]="this.sharedService.customStyleMulti(['txtModalTheme', 'bodyModalTheme'])"></gallery>   
    <div class="modal-footer" [ngStyle]="this.sharedService.customStyleMulti(['txtModalTheme', 'bodyModalTheme'])">
        <div style="display: inline-flex; width: 100%;">
            <div [ngClass]="this.responsiveStyle({ 'vertical': 'card-title-price-handset', 'horizontal': 'card-title-price-desktop',})"
            [ngStyle]="this.sharedService.customStyleMulti(['bodyModalTheme'])"    
            style="margin-top: auto; margin-bottom: auto; display: inline-flex; width: 85%; font-size: 20px; float: right">
                <span class="modal-bar-price" [ngStyle]="this.sharedService.customStyle('barPriceModalTheme')" >{{offer.bar_price | currency:'USD' : 'symbol' : '1.0-0'}}</span>
                &nbsp;<span [ngStyle]="this.sharedService.customStyle('priceModalTheme')"> {{offer.ofr_price | currency:'USD' : 'symbol' : '1.0-0'}}/nt</span>
            </div> &nbsp;&nbsp;&nbsp;&nbsp; 
            <button type="button" 
            class="button-request button-theme-temp"
            [ngStyle]="this.sharedService.customStyle('buttonRequestTheme')"
            style=" margin-left: auto; margin-right: 0; text-shadow: 0 0 0px #ffffff; width: 120px;"
            (click)="openModalTermsAndConditions(termsAndConditions)">REQUEST</button>
        </div>
    </div>
</div>
<ng-template #termsAndConditions >
    <app-offer-photo-terms [offer]="offer" [modalRef]="this.modalRef"></app-offer-photo-terms>
</ng-template>

