import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { Offer } from '../../model/offer';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CommonModule } from '@angular/common';
import { GalleryItem, GalleryModule, ImageItem } from 'ng-gallery';
import Utils from '../../_utils/utils';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { ResponsiveMode } from '../../enum/responsiveModes';
import { OfferPhotoTermsComponent } from '../offer-photo-terms/offer-photo-terms.component';
import { SharedService } from '../../_services/shared.service';

@Component({
  selector: 'app-offer-photo-gallery',
  standalone: true,
  imports: [
    CommonModule,
    GalleryModule,
    OfferPhotoTermsComponent
  ],
  templateUrl: './offer-photo-gallery.component.html',
  styleUrl: './offer-photo-gallery.component.css'
})
export class OfferPhotoGalleryComponent implements OnInit {

  @Input()
  offer!: Offer | null;
  @Input()
  offers!: Offer[] | null;
  @Input()
  index: number = 0;  
  @Input()
  modalRef!: BsModalRef;
  images: GalleryItem[] = [];



  viewMode: string = ResponsiveMode.horizontal;

  config = {
    animated: true,
    keyboard: true,
    backdrop: false,
    ignoreBackdropClick: false,
    class: 'modal-dialog-centered modal-photo-gallery modal-lg'

  };

  constructor(
    private responsive: BreakpointObserver,
    private modalService: BsModalService,
    public sharedService: SharedService
    ) {
  }

  ngOnInit(): void {

    this.responsive.observe([
      Breakpoints.HandsetPortrait,
      Breakpoints.HandsetLandscape,
      Breakpoints.TabletPortrait,
      Breakpoints.TabletLandscape,
      Breakpoints.Medium,
      Breakpoints.Small

    ])
      .subscribe(result => {

        const breakpoints = result.breakpoints;

        if(breakpoints[Breakpoints.HandsetPortrait]){
          this.viewMode = ResponsiveMode.vertical;
        }else {
          this.viewMode = ResponsiveMode.horizontal;
        }
        
      });
      this.getImages();

  }

  getImages(){
    const photos = this.sharedService.offer.amenities?.room_photo_urls;
    this.images = [];

    if(!photos) return;
    for(const photo of photos){
      this.images.push(new ImageItem({src: photo, thumb: photo}));
    }
  }

  openModalTermsAndConditions(openTemplate: TemplateRef<any>) {

    if(this.modalRef){
      this.modalRef.hide();
    }

    setTimeout(() => {
      this.modalRef = this.modalService.show(openTemplate, this.config);
    }, 0)
  }

  responsiveStyle(styleObj: {[key: string]: string}): {[key: string]: boolean} {   
    return Utils.responsiveStyle(this.viewMode, styleObj)
  } 

}
