import { ResponsiveMode } from "../enum/responsiveModes";

export default class Utils {

    static responsiveStyle(mode: string, styleObj: {[key: string]: string}): {[key: string]: boolean} {                 
        if (mode === ResponsiveMode.horizontal){
          return {[styleObj[ResponsiveMode.horizontal]]: true}
        } else {
          return {[styleObj[ResponsiveMode.vertical]]: true}
        }
      } 

    static offerIndex(index: number, viewMoreToggle: boolean, numOffers: number): string {
      if(!viewMoreToggle) {return ''}
      return (index + 1) + '/' + numOffers.toString();
    }
  

}