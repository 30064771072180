import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { OfferConfirmationComponent } from './components/offer-confirmation/offer-confirmation.component';
import { OfferDeclinedComponent } from './components/offer-declined/offer-declined.component';
import { SharedService } from './_services/shared.service';


@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet, HomeComponent, OfferConfirmationComponent, OfferDeclinedComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})
export class AppComponent implements OnInit {

  title = 'client';

  constructor(public sharedService: SharedService){

  }
  ngOnInit(): void {
  }


}
    // Renderer2 DOM MANIPULATION
      // private isLightTheme: boolean = true;
  // private isDarkTheme: boolean = false;
  // private isCustomTheme: boolean = false;
  // private _selectedTheme!: {[key: string]: string[][]}[];

  // public bodyLightTheme = [['color', 'black'], ['background-color', '#FBF5DF'], ['font-family', 'Roboto, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif']];
  // public bodyDarkTheme = [['color', 'white'], ['background-color', '#161616'], ['font-family', 'Verdana, Geneva, Tahoma, sans-serif']];
  // public bodyCustomTheme = this.bodyDarkTheme;

  // public cardLightTheme = [['color', 'black'], ['background-color', '#FBFCF8'], ['font-family', 'inherit']];
  // public cardDarkTheme = [['color', 'white'], ['background-color', '#212121'], ['font-family', 'inherit']];
  // public cardCustomTheme = this.cardDarkTheme;

  // public txtGreetingLightTheme = [['color', 'black'], ['font-family', 'inherit']];
  // public txtGreetingDarkTheme = [['color', '#ffffff99'], ['font-family', 'inherit']];
  // public txtGreetingCustomTheme = this.txtGreetingDarkTheme;

  // public txtGreetingBrightLightTheme = [['color', 'black'], ['font-weight', 'bold'], ['font-family', 'inherit']];
  // public txtGreetingBrightDarkTheme = [['color', 'white'], ['font-family', 'inherit']];
  // public txtGreetingBrightCustomTheme = this.txtGreetingBrightDarkTheme;

  // public cardHeaderLightTheme = [['color', 'black'], ['background-color', '#a41b32'], ['font-family', 'inherit']];
  // public cardHeaderDarkTheme = [['color', 'white'], ['background-color', '#2d2d2d'], ['font-family', 'inherit']];
  // public cardHeaderCustomTheme = this.cardHeaderDarkTheme;

  // public buttonRequestLightTheme = [['color', 'whitesmoke'], ['background-color', '#333333'], ['font-family', 'inherit']];
  // public buttonRequestDarkTheme = [['color', 'whitesmoke'], ['background-color', '#dd1d54'], ['font-family', 'inherit']];
  // public buttonRequestCustomTheme = this.buttonRequestDarkTheme;

  // public cardContentLightTheme = [['color', 'black'], ['font-family', 'inherit']];
  // public cardContentDarkTheme = [['color', 'white'], ['font-family', 'inherit']];
  // public cardContentCustomTheme = this.cardContentDarkTheme;

  // public iconGroupLightTheme = [['color', 'black'], ['font-family', 'inherit']];
  // public iconGroupDarkTheme = [['color', 'white'], ['font-family', 'inherit']];
  // public iconGroupCustomTheme = this.iconGroupDarkTheme;

  // public txtLightTheme = [['color', 'black'], ['font-family', 'inherit']];
  // public txtDarkTheme = [['color', 'white'], ['font-family', 'inherit']];
  // public txtCustomTheme = this.txtDarkTheme;

  // public formLightTheme = [['color', 'black'], ['background-color', '#FBFCF8'], ['font-family', 'inherit']];
  // public formDarkTheme = [['color', 'white'], ['background-color', '#212121'], ['font-family', 'inherit']];
  // public formCustomTheme = this.formDarkTheme;

  // public txtDimLightTheme = [['color', '#00000099'], ['font-family', 'inherit']];
  // public txtDimDarkTheme = [['color', '#ffffff99'], ['font-family', 'inherit']];
  // public txtDimCustomTheme = this.txtDimDarkTheme;

  // public iconColorBlack: boolean = true;

  // constructor(sharedService: SharedService,
  //             private renderer: Renderer2,
  //             private el: ElementRef,
  //             private zone: NgZone         
  //   ) {


  // }

  // selectTheme(){
  //   if(this.isLightTheme){
  //     this._selectedTheme = [{bodyTheme: this.bodyLightTheme}, {cardTheme: this.cardLightTheme}, {txtGreetingTheme: this.txtGreetingLightTheme}, 
  //                             {txtGreetingBrightTheme: this.txtGreetingBrightLightTheme}, {cardHeaderTheme: this.cardHeaderLightTheme}, 
  //                             {buttonRequestTheme: this.buttonRequestLightTheme}, {cardContentTheme: this.cardContentLightTheme},
  //                             {iconGroupTheme: this.iconGroupLightTheme}, {txtTheme: this.txtLightTheme}, {txtDimTheme: this.txtDimLightTheme}, 
  //                             {formTheme: this.formLightTheme}];
  //   } else if(this.isDarkTheme){
  //     this._selectedTheme = [{bodyTheme: this.bodyDarkTheme}, {cardTheme: this.cardDarkTheme}, {txtGreetingTheme: this.txtGreetingDarkTheme}, 
  //                             {txtGreetingBrightTheme: this.txtGreetingBrightDarkTheme}, {cardHeaderTheme: this.cardHeaderDarkTheme},
  //                             {buttonRequestTheme: this.buttonRequestDarkTheme}, {cardContentTheme: this.cardContentDarkTheme},
  //                             {iconGroupTheme: this.iconGroupDarkTheme}, {txtTheme: this.txtDarkTheme}, {txtDimTheme: this.txtDimDarkTheme},
  //                             {formTheme: this.formDarkTheme}];
  //   } else if(this.isCustomTheme) {
  //     this._selectedTheme = [{bodyTheme: this.bodyCustomTheme}, {cardTheme: this.cardCustomTheme}, {txtGreetingTheme: this.txtGreetingCustomTheme}, 
  //                             {txtGreetingBrightTheme: this.txtGreetingBrightCustomTheme}, {cardHeaderTheme: this.cardHeaderCustomTheme},
  //                             {buttonRequestTheme: this.buttonRequestCustomTheme}, {cardContentTheme: this.cardContentCustomTheme},
  //                             {iconGroupTheme: this.iconGroupCustomTheme}, {txtTheme: this.txtCustomTheme}, {txtDimTheme: this.txtDimCustomTheme},
  //                             {formTheme: this.formCustomTheme}];
  //   } else {
  //     this._selectedTheme = [{bodyTheme: this.bodyCustomTheme}, {cardTheme: this.cardCustomTheme}, {txtGreetingTheme: this.txtGreetingCustomTheme}, 
  //                             {txtGreetingBrightTheme: this.txtGreetingBrightCustomTheme}, {cardHeaderTheme: this.cardHeaderCustomTheme},
  //                             {buttonRequestTheme: this.buttonRequestCustomTheme}, {cardContentTheme: this.cardContentCustomTheme},
  //                             {iconGroupTheme: this.iconGroupCustomTheme}, {txtTheme: this.txtCustomTheme}, {txtDimTheme: this.txtDimCustomTheme},
  //                             {formTheme: this.formCustomTheme}];
  //   }
  // }

  // ngOnInit(): void {
  //   // setTimeout(() => {
  //   //   this.buildTheme();      
  //   // }, 0);

  // }


  // buildTheme(): void {
  //   // The set time out is needed to trigger the class change in the DOM, need to find a better solution, but works...
  //   setTimeout(() => this.zone.run(() => {
  //     this.selectTheme();
  //       // for each element item with id in keyValue list, update with list of styles
  //         for(let kv of this._selectedTheme){
  //           Object.entries(kv).forEach(([key, value]) => {                          
  //             // have to cast to HTMLElement because Element does not contain the style prperty              
  //             var themes = Array.from(this.el.nativeElement.querySelectorAll("."+key) as HTMLCollectionOf<HTMLElement>);
  //             for(let item of (themes)) {            
  //               for(let pair of value){
  //                   this.renderer.setStyle(item, pair[0], pair[1], RendererStyleFlags2.Important + RendererStyleFlags2.DashCase);
  //               }
  //             }
  //           });
  //         }

  //       }), 0);
  // }


    // !!!!! Direct DOM Manipulation

    // this.selectTheme();
    // console.log(this._selectedTheme);

    // /* https://stackoverflow.com/questions/28643617/bootstrap-modal-makes-scrollbar-disappear-after-closing */
    // if (typeof document !== 'undefined') {
    //     const body = document.body;
    //     // for each element item with id in keyValue list, update with list of styles
    //     for(let kv of this._selectedTheme){
    //       Object.entries(kv).forEach(([key, value]) => {
    //         // have to cast to HTMLElement because Element does not contain the style prperty
    //           // const test = this.el.nativeElement.querySelectorAll("."+key);
    //           // console.log('test', test);
    //           // this.renderer.setStyle(button, 'background-color', 'red');
    //           var theme = Array.from(document.getElementsByClassName(key) as HTMLCollectionOf<HTMLElement>);
    //           console.log(key);
    //           for(let item of (theme)) {
    //             // The set time out is needed to trigger the class change in the DOM, need to find a better solution, but works...
    //             setTimeout(() => {
    //                 for(let pair of value){
    //                 console.log(pair);
    //                 if(pair[0] == 'background-color'){
    //                   item.style.backgroundColor = pair[1];
    //                 }
    //                 if(pair[0] == 'color'){
    //                   item.style.color = pair[1];
    //                 }
    //                 if(pair[0] == 'font-family'){
    //                   item.style.fontFamily = pair[1];
    //                 }
    //                 // item.style.setProperty(pair[0], pair[1]);
    //                 }
    //               }, 0);
    //           }
            
    //       });
    //     }
    //     this.isLoading = false;
    // }

  // }




      // !!!!!
      // works for specified classes
      // for(let item of (d_theme)) {
      //   // The set time out is needed to trigger the class change in the DOM, need to find a better solution, but works...
      //   setTimeout(() => {
      //     item.classList.toggle(this.defaultTheme);
      //     item.classList.toggle(this.selectedTheme);
      //     }, 0);
      // }