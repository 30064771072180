import { AfterViewInit, Component, ElementRef, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Offer } from '../../model/offer';
import { MatCommonModule } from '@angular/material/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import {FormsModule} from '@angular/forms';
import { ModalModule, BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import {BreakpointObserver, Breakpoints, LayoutModule} from '@angular/cdk/layout';
import { Observable, map } from 'rxjs';
import { SharedService } from '../../_services/shared.service';
import { OfferService } from '../../_services/offer/offer.service';
import Utils from '../../_utils/utils'
import { OfferCardGreetingComponent } from '../offer-card-greeting/offer-card-greeting.component';
import { OfferPhotoGalleryComponent } from '../offer-photo-gallery/offer-photo-gallery.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ResponsiveMode } from '../../enum/responsiveModes';
import { OfferImageComponent } from '../offer-card/offer-image/offer-image.component';
import { OfferIconComponent } from '../offer-card/offer-icon/offer-icon.component';
import { OfferContentComponent } from '../offer-card/offer-content/offer-content.component';

@Component({
  selector: 'app-offer-card-list-responsive',
  standalone: true,
  imports: [
    CommonModule,  
    MatCommonModule,
    MatCardModule,
    FormsModule,
    ModalModule,
    RouterLink,
    LayoutModule,
    OfferCardGreetingComponent,
    OfferPhotoGalleryComponent,
    NgxSpinnerModule,
    OfferImageComponent,
    OfferIconComponent,
    OfferContentComponent
  ],
  providers: [BsModalService],
  templateUrl: './offer-card-list-responsive.component.html',
  styleUrl: './offer-card-list-responsive.component.css'
  
})


export class OfferCardListResponsiveComponent implements OnInit, AfterViewInit {

  @Input()
  offers: Offer[] | null = [];
  viewMoreToggle:boolean = false;

  @ViewChild('searchInput') searchInput!: ElementRef;
  @ViewChild('toggleView') toggleView!: ElementRef;

  modalActive: boolean = false;
  horizontalView: boolean = true;
  vertialView: boolean = false;
  viewMode: string = ResponsiveMode.horizontal;
  responsiveMode = ResponsiveMode;


  modalRef!: BsModalRef;

  modalClose$!: Observable<boolean>;
  modalOpen$!: Observable<boolean>;

  constructor(
    public sharedService: SharedService,
    private offerService: OfferService,
    private modalService: BsModalService, 
    private router: Router, 
    private route: ActivatedRoute,
    private responsive: BreakpointObserver,
    ) {
    }


  ngOnInit(): void {
    this.responsive.observe([
      Breakpoints.HandsetPortrait,
    ])
      .subscribe(result => {
        const breakpoints = result.breakpoints;
        if(breakpoints[Breakpoints.HandsetPortrait]){
          this.viewMode = ResponsiveMode.vertical;
          this.vertialView = true;
          this.horizontalView = false;
        }
        else {
          this.viewMode = ResponsiveMode.horizontal;
          this.vertialView = false;
          this.horizontalView = true;        
        }
      });
  }

  ngAfterViewInit(): void {
   
    this.modalClose$ = this.modalService.onHide.pipe(
      map(res => {
        this.modalActive = false;
        return true;
      })
    );

    this.modalClose$.subscribe(res => {
      /* https://stackoverflow.com/questions/28643617/bootstrap-modal-makes-scrollbar-disappear-after-closing */
      document.getElementsByTagName('body')[0].classList.remove('modal-open');
      this.modalActive = false;
      // html.classList.remove('modal-open');
    });

    this.modalOpen$ = this.modalService.onShow.pipe(
      map(res => {
        this.modalActive = true;
        return res;
      })
    );

      this.modalOpen$.subscribe(res => this.modalActive = true);
  }

  responsiveStyle(styleObj: {[key: string]: string}): {[key: string]: boolean} {   
    return Utils.responsiveStyle(this.viewMode, styleObj)
  } 

  closeModal(modalRef: BsModalRef){
    modalRef.hide();
    this.modalRef.hide();
  }

  acceptOffer(modalRef: BsModalRef) {
    if(this.offers){
      this.sharedService.offer = this.offers[this.sharedService.currentOfferIndex];
      this.offerService.setOfferAccepted(this.sharedService.offer).pipe(
          map(() => {
            this.closeModal(modalRef)
            this.navigateToOfferConfirmation();
          })
        ).subscribe();
    }
    else{
      // WARN USER SOMETHING WENT WRONG
    }
  }

  declineOffer() {
    if(this.offers){     
      this.sharedService.offer = this.offers[0];
      this.offerService.setOfferDeclined(this.sharedService.offer.prop_id, this.sharedService.offer.offer_alias_id)
      .pipe(
          map(() => {
            this.navigateToOfferDeclined();
          })
        ).subscribe();
    }
    else{
      // WARN USER SOMETHING WENT WRONG
    }
  }

  navigateToOfferConfirmation(){
    this.router.navigate(['/offerConfirmation'], { relativeTo: this.route })
  }

  navigateToOfferDeclined(){
    this.router.navigate(['/offerDeclined'], { relativeTo: this.route })
  }

  openModal(template: TemplateRef<any>, index: number) {
    
    if(this.offers) {
      this.sharedService.setSelectedOffer(index, this.offers, false);
    }    
    this.modalRef = this.modalService.show(template, this.sharedService.config); 
  }

  toggleSearch(){
    this.viewMoreToggle = !this.viewMoreToggle;
    this.searchInput.nativeElement.value = '';
    setTimeout(()=>{ // this will make the execution after the above boolean has changed
      this.searchInput.nativeElement.focus();
    },0); 
  }

}
