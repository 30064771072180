import { ApplicationConfig, Renderer2, importProvidersFrom} from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { provideClientHydration } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule, HttpClientXsrfModule, provideHttpClient, withInterceptors, withXsrfConfiguration } from '@angular/common/http';
import { LoadingInterceptor } from './_interceptors/Loading.interceptor';
import { provideServerRendering } from '@angular/platform-server';



export const appConfig: ApplicationConfig = {
  providers: [provideRouter(routes), 
              provideClientHydration(), 
              provideAnimations(), 
              provideAnimations(),
              provideHttpClient(withInterceptors([LoadingInterceptor])),
            ]
};
