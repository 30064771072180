
export class config {

    // https://github.com/angular/universal/blob/main/docs/gotchas.md

    // local storage issue
    // https://rollbar.com/blog/how-to-handle-localstorage-is-not-defined-error-javascript/
    public static getEnvVar(hostName:string): string {

        let endpoints = {'localhost':'http://localhost:5000/', 
                        'customer.develop.luxepricing.com':'https://upsell-api-dev.luxepricing.com/', 
                        'default':'https://upsell-api-dev.luxepricing.com/',};

        // endpoints = {'localhost':'https://upsell-api-dev.luxepricing.com/', 
        //                 'customer.develop.luxepricing.com':'https://upsell-api-dev.luxepricing.com/', 
        //                 'default':'https://upsell-api-dev.luxepricing.com/',};

        let key = hostName as keyof typeof endpoints;
        let endPoint = '';

        if(endpoints[key]){
            endPoint = endpoints[key] as string;
        }else{
            endPoint = endpoints['default'];
        }

        return endPoint;
    }
}



        // data = {
        //     endPointUpsell: 'https://upsell-api-dev.luxepricing.com/',
        //     endPoint: 'https://customer.develop.luxepricing.com/'
        // };

        // data = {
        //     endPointUpsell: 'http://localhost:5000/',                    
        //     endPoint: 'http://localhost:9000/'
        //     // endPoint: 'https://upsell-api-dev.luxepricing.com/'
        // };