
<div class="backdrop-blur" (click)="this.closeModal()"></div>
<div *ngIf="offer" >
    <div style="border-radius: 7px;" [ngStyle]="this.sharedService.customStyleMulti(['txtModalTheme', 'bodyModalTheme'])">

        <div class="modal-header" style="border: none !important; height: 40px !important;" [ngStyle]="this.sharedService.customStyleMulti(['txtModalTheme', 'bodyModalTheme'])">
            <p></p>
            <div class="button-close" (click)="this.modalRef.hide()">                
                <img  src='{{ "assets/svg/close-70" + this.sharedService.iconColorClose + ".svg" }}' class="image-close"/>
            </div>
        </div>

        <div style="width: 90%; height: 500px; margin-left: auto; margin-right: auto;">
            <div                  
            style="height: 90%; width: 100%; 
            overflow-y: scroll;
            scrollbar-width: thin;
            margin-top: 50px;
            "
            [ngStyle]="this.sharedService.customStyleMulti(['txtModalTheme', 'bodyModalTheme',  'scrollModalTheme'])"
            >
                <div style="width: 95%;">

                    <p class="terms-txt"  [ngStyle]="this.sharedService.customStyle('txtModalTheme')">Dear Guest,</p>
                   
                    <p class="terms-txt" [ngStyle]="this.sharedService.customStyle('txtModalTheme')">Thank you for considering an upgrade to our {{offer.amenities.upsell_room_name | titlecase}} for an additional {{offer.ofr_price | currency:'USD' }} per night, 
                                        plus tax. This fee will be added to the base rate you've already paid for your current room.</p>
                    
                    <p class="terms-txt" [ngStyle]="this.sharedService.customStyle('txtModalTheme')">Here's what happens next:</p>
                    
                    <div style="display: flex;">
                        <li [ngStyle]="this.sharedService.customStyle('txtModalTheme')"></li>
                        <p class="terms-txt" [ngStyle]="this.sharedService.customStyle('txtModalTheme')"><span class="terms-txt" style="font-weight: bold;" [ngStyle]="this.sharedService.customStyle('txtModalTheme')">Review Process: </span>Our property team will review your upgrade request within 24 hours. We strive to accommodate all our guests and will do our best to fulfill your request.</p>
                    </div>    
                    <div style="display: flex;" >
                        <li [ngStyle]="this.sharedService.customStyle('txtModalTheme')"></li>
                        <p class="terms-txt" [ngStyle]="this.sharedService.customStyle('txtModalTheme')"><span class="terms-txt" style="font-weight: bold;" [ngStyle]="this.sharedService.customStyle('txtModalTheme')">Notification: </span>{{getNotification(this.offer ? this.offer.confirmation_type : 'E')}}.</p>
                    </div>   
                    <div style="display: flex;">
                        <li [ngStyle]="this.sharedService.customStyle('txtModalTheme')"></li>
                        <p class="terms-txt" [ngStyle]="this.sharedService.customStyle('txtModalTheme')"><span class="terms-txt" style="font-weight: bold;" [ngStyle]="this.sharedService.customStyle('txtModalTheme')">Upgrade Policy: </span>Please note, once your upgrade request is accepted, the additional charge is non-refundable. We recommend reviewing your plans to ensure the upgrade aligns with your stay.</p>
                    </div>    

                    <p class="terms-txt" [ngStyle]="this.sharedService.customStyle('txtModalTheme')">We value your choice to stay with us and are excited to welcome you. If you have any questions or need further assistance, please feel free to reach out to our guest services team at {{this.offer ? this.offer.prop_info : ''}}.</p>
                    
                    <p class="terms-txt" [ngStyle]="this.sharedService.customStyle('txtModalTheme')">Looking forward to making your stay extra special!</p>
                    
                    <p class="terms-txt" [ngStyle]="this.sharedService.customStyle('txtModalTheme')">Warm regards,</p>
                    
                    <p class="terms-txt" [ngStyle]="this.sharedService.customStyle('txtModalTheme')">{{offer.prop_name | titlecase}}</p>
                </div>            
            </div>
        </div>                
        <div class="modal-footer" [ngStyle]="this.sharedService.customStyleMulti(['txtModalTheme', 'bodyModalTheme'])">
            <div style="width: 100%;" style="margin-left: auto; margin-right: 0px; right: float;" [ngStyle]="{'display' : this.viewMode === 'vertical' ? 'block' : 'flex'}" >
                <label style="display: flex; margin-left: auto; margin-right: 0px; right: float;" [ngStyle]="this.sharedService.customStyle('txtModalTheme')">                    
                    <p style="margin-top: 9px; font-weight: bold; margin-left: auto; margin-right: 0px; right: float;">Acknowledgment&nbsp;&nbsp;</p>
                    <div style="margin-left: auto; margin-right: 0px; right: float;" [ngStyle]="{'margin-right' : this.viewMode === 'vertical' ? '0px' : '15px'}" >                        
                        <input type="checkbox" style="margin-left: 0px; margin-top: 15px; position: absolute;" (click)="toggleAcknowledgment()">
                        &nbsp;<span class="checkmark" style="margin-bottom: -2px !important; margin-right: 10px;" ></span>
                    </div>
                </label>
                    <button   data-dismiss="modal" type="button" 
                    class="button-request button-request-terms" 
                    [ngStyle]="{'background-color': !this.acknowledgment ? 'rgba(221,29,84,0.75)' : 'rgba(221,29,84,1)' }"
                    style=" margin-left: auto; margin-right: 0; text-shadow: 0 0 0px #ffffff; width: 180px; height: 40px; color: whitesmoke !important;"                    
                    (click)="this.acceptOffer()">REQUEST UPGRADE</button>
            </div>
        </div>            
    </div>
</div>
