import { AfterViewInit, Component, ElementRef, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Offer } from '../../model/offer';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCommonModule } from '@angular/material/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {FormsModule} from '@angular/forms';
import { ModalModule, BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { GalleryItem, GalleryModule, ImageItem } from 'ng-gallery';
import { NgSelectModule } from '@ng-select/ng-select';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import {BreakpointObserver, Breakpoints, LayoutModule} from '@angular/cdk/layout';
import { Observable, map } from 'rxjs';
import { SharedService } from '../../_services/shared.service';

@Component({
  selector: 'app-offer-card-list-mobile',
  standalone: true,
  imports: [
    CommonModule,  
    MatTabsModule,
    MatButtonModule,
    MatCommonModule,
    MatCardModule,
    MatExpansionModule,
    MatInputModule,
    MatFormFieldModule,
    FormsModule,
    ModalModule,
    GalleryModule,
    NgSelectModule,
    RouterLink,
    LayoutModule
  ],
  providers: [BsModalService],
  templateUrl: './offer-card-list-mobile.component.html',
  styleUrl: './offer-card-list-mobile.component.css'
})


export class OfferCardListMobileComponent implements OnInit, AfterViewInit {


  
  @Input()
  offers!: Offer[] | null;
  numberOfOffersToShow = 3;
  primaryOffers!: Offer[] | null;
  collapsed:boolean = true;
  currentIndex:number = -1;
  MIN_LIST_INDEX:number = 3;
  max_list_index:number = 3;
  view_more_toggle:boolean = false;

  images: GalleryItem[] = [];
  selected_offer!: Offer;


  @ViewChild('searchInput') searchInput!: ElementRef;




  // Mobile responsive variables
  isHandsetPortriat: boolean = false;
  isHandsetLandscape: boolean = false;
  isTabletPortriat: boolean = false;
  isTabletLandscape: boolean = false;
  isNormal: boolean = false;

  
  modalActive: boolean = false;

  modalRef!: BsModalRef;
  config = {
    animated: true,
    keyboard: true,
    backdrop: false,
    ignoreBackdropClick: false,
    class: 'modal-dialog-centered modal-photo-gallery modal-lg' 

  };

  modalClose$!: Observable<boolean>;
  modalOpen$!: Observable<boolean>;

  constructor(
    public sharedService: SharedService,
    private modalService: BsModalService, 
    private router: Router, 
    private route: ActivatedRoute,
    private responsive: BreakpointObserver
    ) {



    }
  ngAfterViewInit(): void {
    
   
    this.modalClose$ = this.modalService.onHide.pipe(
      map(res => {
        this.modalActive = false;
        return true;
      })
    );

    this.modalClose$.subscribe(res => {
      /* https://stackoverflow.com/questions/28643617/bootstrap-modal-makes-scrollbar-disappear-after-closing */
      document.getElementsByTagName('body')[0].classList.remove('modal-open');
      this.modalActive = false;
      // html.classList.remove('modal-open');
      

    });

    this.modalOpen$ = this.modalService.onShow.pipe(
      map(res => {
        this.modalActive = true;
        return res;
      })
    );

      this.modalOpen$.subscribe(res => this.modalActive = true);


  }

  ngOnInit(): void {



  }

  closeTermsAndConditionsModal(modalRef: BsModalRef){

    this.sharedService.offer = this.selected_offer;  
    modalRef.hide();
    this.navigateToOfferConfirmation();  
  }

  navigateToOfferConfirmation(){
    this.router.navigate(['/offerConfirmation'], { relativeTo: this.route })
  }

  navigateToOfferDeclined(){
    this.router.navigate(['/offerDeclined'], { relativeTo: this.route })
  }


  openModal(template: TemplateRef<any>, index: number) {
    this.getImages(index);
    this.setSelectedOffer(index);    
    this.currentIndex = index;
    this.modalRef = this.modalService.show(template, this.config);
    
  }

  openModalTermsAndConditions(openTemplate: TemplateRef<any>, index?: number) {

    if(index){
      this.setSelectedOffer(index);
    }

    if(this.modalRef){
      this.modalRef.hide();
    }

    setTimeout(() => {
      this.modalRef = this.modalService.show(openTemplate, this.config);
    }, 100)

  }


  closeAllModals() {

    for (let i = 1; i <= this.modalService.getModalsCount(); i++) {
      this.modalService.hide(i);
    }

  }



  getImages(index: number){

    if(!this.offers) return;
    
    const photos = this.offers[index].amenities?.room_photo_urls;

    this.images = [];

    if(!photos) return;
    for(const photo of photos){
      this.images.push(new ImageItem({src: photo, thumb: photo}));
    }

  }

  AfterViewChecked(){

  }

  toggleSearch(){
    this.view_more_toggle = !this.view_more_toggle;
    
    if(this.view_more_toggle === true){
      this.numberOfOffersToShow = this.offers!.length;
    }
    else{
      this.numberOfOffersToShow = 3;
    }


    setTimeout(()=>{ // this will make the execution after the above boolean has changed
      this.searchInput.nativeElement.focus();
    },0); 

  }


  clickForMoreDetails(j: number, i: number, attr: string): string{
    
    let ofr_attrs = this.offers![i].amenities.upsell_attributes.slice(0, this. max_list_index);

    let contentHeight = 0;
    let arrayHeights = []

    for (let k = 0; k < ofr_attrs.length; k++) {
        if(document.getElementById('content-' + i.toString() + '-' + i)){;
          contentHeight = contentHeight + document.getElementById('content-' + i.toString() + '-' + k)!.offsetHeight + 10;
        }
        arrayHeights.push(contentHeight);
    }

    let b_idx = 2;

  //  for (let k = 0; k < arrayHeights.length; k++) {
  //     if(arrayHeights[k] > 140){
  //       b_idx = k;
  //       // offer.max_list_attrs = k + 1;
  //       // console.log(i, j, b_idx);
  //       break;
  //     }    
  //  }


    let attr_string = (j==b_idx && this.currentIndex !== i) || (j==b_idx && this.currentIndex === i && this.collapsed) ? "Click for more details" + "\n" : attr.substring(attr.indexOf(':'), attr.length);

    if((j>b_idx && this.currentIndex !== i) || (j>b_idx && this.currentIndex === i && this.collapsed)){
      attr_string = '...'
    }
    
    return attr_string;
  }


  togglAttrSpan(j: number, i: number, attr: string): boolean{
    
    let ofr_attrs = this.offers![i].amenities.upsell_attributes.slice(0,this. max_list_index);

    let contentHeight = 0;
    let arrayHeights = []

    for (let k = 0; k < ofr_attrs.length; k++) {
        if(document.getElementById('content-' + i.toString() + '-' + i)){;
          contentHeight = contentHeight + document.getElementById('content-' + i.toString() + '-' + k)!.offsetHeight + 10;
        }
        arrayHeights.push(contentHeight);
    }

    let b_idx = 3;

   for (let k = 0; k < arrayHeights.length; k++) {
      if(arrayHeights[k] > 140){
        b_idx = k;
        break;
      }    
   }


    let attr_bool = true;

    if((j>b_idx && this.currentIndex !== i) || (j>b_idx && this.currentIndex === i && this.collapsed)){
      attr_bool = false;
    }
    
    return attr_bool;
  }

  toggleCollapse(index: number) {

    if(index !== this.currentIndex){
      this.collapsed = false;
    }else{
      this.collapsed = !this.collapsed;
    }

    this.currentIndex = index;

    // if(document.getElementById('contentParagraph' + index.toString())){
    //   this.contentParagraphHeight = document.getElementById('contentParagraph' + index.toString())!.offsetHeight;
    // }


    // if(document.getElementById('contentLineHeight' + index.toString())){
    //   this.ROW_CONTENT_HEIGHT = document.getElementById('contentLineHeight' + index.toString())!.offsetHeight + 10;  
    // }

    // let contentHeight = 0;


    // contentHeight = contentHeight + document.getElementById('content-' + index.toString())!.offsetHeight;
    // this.CONTENT_HEIGHT = contentHeight;
    // console.log('contentHeight', contentHeight);
    

    this.setMaxListIndex(this.collapsed, index);
    // this.setCardHeigt(index);
    // this.setRequestButtonPosition(index);
    this.setSelectedOffer(index);

  }


  contentStyle(i: number, j:number): {[key: string]: boolean} {
  
    return {
      'even-more-opacity': (j == 0 && this.currentIndex !== i) || (j == 0 && this.currentIndex === i && this.collapsed), 
      'more-opacity': (j == 1 && this.currentIndex !== i) || (j == 1 && this.currentIndex === i && this.collapsed), 
      'some-opacity': (j == 2  && this.currentIndex !== i) || (j == 2 && this.currentIndex === i && this.collapsed),
      'card-details-txt' : (j == 3  && this.currentIndex !== i) || (j == 3 && this.currentIndex === i && this.collapsed),
      'card-content-expanded': this.currentIndex === i && !this.collapsed, 
      'card-content-contracted': this.currentIndex != i && this.collapsed || this.currentIndex === i && this.collapsed
    }

  }


  hiddenStyle(index: number): {[key: string]: boolean} {
    if(this.currentIndex == index && !this.collapsed)
    {
      return {'unhidden': true}
    }    
    return {'unhidden': false};
  }


  blurBackground(): {[key: string]: boolean} {
    // console.log('Blur', this.modalActive);
    if(this.modalActive)
    {
      return {'backdrop-blur': true}
    }    
    return {'backdrop-blur': false};
  }


  blurCardBackground(index: number): {[key: string]: boolean} {

    if(this.modalActive && index == this.currentIndex)
    {
      // console.log('Blur', this.modalActive);
      return {'backdrop-blur': true}
    }    
    return {'backdrop-blur': false};
  }



  setCardHeigt(index: number) {
    if(!this.offers) return;
    this.cardHeight =  
    this.MIN_CARD_HEGIHT - 0
    + this.contentParagraphHeight 
    + this.CONTENT_HEIGHT;
  }


  cardHeight: number = 550;
  MIN_CARD_HEGIHT: number = 550;
  CONTENT_HEIGHT: number = 0;
  ROW_CONTENT_HEIGHT:number = 0;

  contentParagraphHeight = 0;
  MIN_REQ_BTN_MARGIN:number = 5;
  requestButtonMargin:number = this.MIN_REQ_BTN_MARGIN;

  setRequestButtonPosition(index: number) {
    if(!this.offers) return;
    // this.requestButtonMargin = this.MIN_REQ_BTN_MARGIN + (this.offers[index].upsell_attributes.length - this.MIN_LIST_INDEX + 2) * this.ROW_CONTENT_HEIGHT * 2;
    this.requestButtonMargin = 
    this.MIN_REQ_BTN_MARGIN - 0
    + this.contentParagraphHeight 
    // + this.CONTENT_HEIGHT

    // + (this.offers[index].upsell_attributes.length - this.MIN_LIST_INDEX + 1) * this.ROW_CONTENT_HEIGHT
    // -20
    ;


  }

  setMaxListIndex(collapsed: boolean, index: number){
    if(!this.offers) return;
    if(collapsed) {
      this.max_list_index = this.MIN_LIST_INDEX;
    }else{
      this.max_list_index = this.offers[index].amenities.upsell_attributes.length;
    }

  }

  setSelectedOffer(index: number){
    
    if(!this.offers) return;
    this.selected_offer = this.offers[index];
  }






}
