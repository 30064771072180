
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">




<!-- 
    Modal scrolling issue:
    https://stackoverflow.com/questions/25070144/bootstrap-modal-removes-scroll-bar/25070157#25070157 

-->
<!-- <script>

jQuery(document).ready(function () {
    jQuery('.closeit').click(function () {
        jQuery('html').removeClass('modal-open');
                });
            });


jQuery(document).ready(function () {
    jQuery('.closeit').click(function () {
        jQuery('body').removeClass('modal-open');
                });
            });


</script> -->

<ng-template #template>

    <div class="modal-header" style=" border: none !important; background-color:  #161616 !important; height: 40px !important; ">
    
        <p class="modal-room-title" *ngIf="this.offers">{{this.selected_offer.amenities.upsell_room_name}}</p>
        <div class="button-close" (click)="modalRef.hide()">
            <img  src = "assets\svg\close-70.svg" class="image-close"/>
        </div>
    </div>

   <gallery style="width: 100%; background-color: #161616;"
        [items]="images">
   </gallery>   

   <div class="modal-footer" style="background-color: #161616;">
    
    <div style="display: inline-flex; width: 100%;">
        <div class="card-title-price" style="margin-top: auto; margin-bottom: auto; display: inline-flex; width: 85%; color: white; font-size: 20px; float: right">
            <span class="modal-bar-price">${{this.selected_offer.bar_price}}</span>&nbsp;<span> ${{this.selected_offer.ofr_price}}/nt</span>
        </div> &nbsp;&nbsp;&nbsp;&nbsp;
        <button type="button" class="button-request" style=" margin-left: auto; margin-right: 0; text-shadow: 0 0 0px #ffffff; width: 120px;"
        (click)="openModalTermsAndConditions(termsAndConditions)">REQUEST</button>
    </div>
   </div>
</ng-template>


<ng-template #termsAndConditions >

    <div >
        <div style="background-color:  #161616 !important; border-radius: 7px;">

            <div class="modal-header" style="color: white; border: none !important; background-color:  #161616 !important; height: 40px !important; ">
                <p></p>
                <div class="button-close" (click)="modalRef.hide()">
                    <img  src = "assets\svg\close-70.svg" class="image-close"/>
                </div>
            </div>
   

            <!-- class="modal-body" -->

            <div style="width: 90%; height: 500px; margin-left: auto; margin-right: auto;">
                <div                  
                style="height: 90%; width: 100%; 
                overflow-y: scroll;
                scrollbar-color: #5a5959 #2d2d2d;
                scrollbar-width: thin;
                margin-top: 50px;
                background-color: #161616; color: white;                
                "
                >
                <div style="width: 95%;">

                    <p class="terms-txt"> Thank you for your request.</p>
                    <p>Your pre tax cost per stay night is {{this.selected_offer.ofr_price | currency:'USD' }} for {{this.selected_offer.los}} nights for a total of {{this.selected_offer.los * this.selected_offer.ofr_price | currency:'USD' }}.</p>
                    <p class="terms-txt"> Upon submitting this request, the property team will typically review and accept or decline the request within 24 hours and you will be notified via an email communication. 
                    If declined, this is typically due to extenuating inventory circumstances preventing the upgrade at this time. 
                    Please note that accepted upgrade requests are non-refundable.!</p>
                    <br>
                    <h1 class="terms-txt" style="color: white;">Terms & Conditions</h1>                     
                    <div class="modal-dialog" style="background-color:  #161616 !important; height: 100%; border: none;">
                        <p class="terms-txt">Our Terms and Conditions were last updated on 1/1/2024.</p>
                        <p class="terms-txt">Please read these terms and conditions carefully before using Our Service.</p>
        
                        <h2 class="terms-txt" style="font-size: 24 px !important;">Acknowledgment</h2>
                        <p class="terms-txt"></p>
                        <p class="terms-txt">These are the Terms and Conditions governing the use of this Service and the agreement that operates
                            between You and the Company. These Terms and Conditions set out the rights and obligations of all users
                            regarding the use of the Service.
                        </p>
                        <p></p>            
                        <p class="terms-txt" >Your access to and use of the Service is conditioned on Your acceptance of and compliance with these Terms and Conditions. These Terms and Conditions apply to all visitors, users and others who access or use the Service.</p>
                        <p></p>
                        <p class="terms-txt" >By accessing or using the Service You agree to be bound by these Terms and Conditions. If You disagree with any part of these Terms and Conditions then You may not access the Service.</p>
                        <p></p>
                        <p class="terms-txt" >You represent that you are over the age of 18. The Company does not permit those under 18 to use the Service.</p>
                        <p></p>
                        <p class="terms-txt" >Your access to and use of the Service is also conditioned on Your acceptance of and compliance with the Privacy Policy of the Company. Our Privacy Policy describes Our policies and procedures on the collection,
                            use and disclosure of Your personal information when You use the Application or the Website and tells You about Your privacy rights and how the law protects You. Please read Our Privacy Policy carefully before using Our Service.</p>
                        <p></p>
                        <h2 class="terms-txt" style="font-size: 24 !important;">User Accounts</h2>
                        <p></p>            
                        <p class="terms-txt" >When You create an account with Us, You must provide Us information that is accurate, complete, and
                            current at all times. Failure to do so constitutes a breach of the Terms, which may result in immediate
                            termination of Your account on Our Service.
                            </p>
                        <p></p>
                        <p class="terms-txt" >You are responsible for safeguarding the password that You use to access the Service and for any activities
                            or actions under Your password, whether Your password is with Our Service or a Third-Party Social Media
                            Service.
                            </p>
                        <p></p>
                        <p class="terms-txt" >You agree not to disclose Your password to any third party. You must notify Us immediately upon becoming
                            aware of any breach of security or unauthorized use of Your account.</p>
                        <p></p>
                        <p class="terms-txt" >You may not use as a username the name of another person or entity or that is not lawfully available for
                            use, a name or trademark that is subject to any rights of another person or entity other than You without
                            appropriate authorization, or a name that is otherwise offensive, vulgar or obscene.
                            </p>
                        <p></p>
                    </div>

                </div>
                    
                </div>
            </div>
            
     
        
           <div class="modal-footer" style="background-color: #161616;">
            
            <div style="display: inline-flex; width: 100%;">
                <label style="margin-left: auto; margin-right: 0; right: float; color: white;">Acknowledgment
                    <!-- <span style="width: 5px;"></span> -->
                    
                    <input type="checkbox" style="margin-top: 12px; position: absolute;">
                    &nbsp;
                    <span class="checkmark" style="margin-bottom: -2px !important;"></span>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <button type="button" class="button-request" style=" margin-left: auto; margin-right: 0; width: 100px;"
                    


                    (click)="this.closeTermsAndConditionsModal(modalRef)">ACCEPT</button>
                </label>
    
            </div>
           </div>            
        </div>
    </div>
</ng-template>




<!-- <div [ngClass]="this.blurBackground()"></div> -->

<div style="height: 100%;">
    <mat-card class="offer-card-greeting">
        <div  
            class="container"
            style="
                color: rgba(255, 255, 255, 0.90); 
                margin-top: 10px;
                margin-right: auto; 
                margin-left: auto;
                font-family: Verdana, Geneva, Tahoma, sans-serif;
                
                ">
            <p class="greeting-txt">Hi Patrick!</p>
            <div style="display: flex;" >
                <p class="greeting-txt">You are currently booked at the Planet Hollywood Resort & Casino into a 
                    <span style="color: white;  text-transform: uppercase; ">Resort King Room</span> from 6/25-27 under confirmation number CXJ734.</p>
            </div>

            <p class="greeting-txt" >We have some premium rooms available over these dates and would like to extend this personal upgrade offer to you. Reserve now for no upfront charge while rooms last!</p>
        </div>    
    </mat-card>
    <!--  -->
    <form class="example-form" [hidden]="!view_more_toggle" style="width: 90%;">
        <div style="height: 70px; width: 100%;" >
          
          <input 
          style="
          padding-left: 15px;
          color: white !important;
          font-family: inherit;
          height: 50px;
          width: 100%;
          background-color: #2d2d2d;;
          margin-bottom: 30px;
     
          "
          #searchInput
     
          placeholder="Ex: Room Type (Suite), Beds, Sqft, etc" 
          value="">
        </div>
    </form>
<!--         [style.height] = "!collapsed && this.currentIndex == i ? cardHeight.toString()+'px !important': MIN_CARD_HEGIHT.toString()+'px !important'" -->
    <mat-card 
        class="offer-card"  
        [ngStyle]="{'transition: all 0.5s;': !collapsed && this.currentIndex == i}"

        *ngFor="let offer of offers?.slice(0, this.numberOfOffersToShow);let i = index">
        
        <!-- #375a7f -->

        <div [ngClass]="this.blurCardBackground(i)"></div>

            <div style="display: block;" >
        
                <div 
                class="container"
                style="
                display: block; 
                margin-left: 0px;
                background-color: #2d2d2d;
                border-radius: 5px 5px 0px 0px;
                box-sizing: border-box;
                height: 48px;
                width: 100%;            
                ">                    
          
  

                <p class="card-header card-title ">
                    {{offer.amenities.upsell_room_name}}                                    
                </p>          

            </div>

            <div class="box"  (click)="openModal(template, i)" [ngClass]="{'image-shake': i === 0}">

                <div
                class="image-hotel-background"                    
                [ngStyle]="{'background-image': 'url(' + offer.amenities.room_photo_url + ')'}">
                    <div
                        mat-card-image
                        style="
                        background-color: rgba(255, 255, 255, .05) !important;
                        -webkit-backdrop-filter: blur(8px) !important;
                        backdrop-filter: blur(8px) !important;
                        height: 100%;  
                        width: 100% !important;                       
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: cover;">                        
                    </div>
                </div>

                <div>
                    <img 
                    mat-card-image         
                    class="image-hotel" 
                    [src]="offer.amenities.room_photo_url">
        
   
                    <div class="ribbon-red"  [hidden]="!offer.is_hot_deal">
                        <div class="txt-red">
                            Hot Deal!
                        </div>
                    </div>

                    <div class="ribbon-blue"  [hidden]="!offer.is_popular">
                        <div class="txt-blue">
                            Popular!
                        </div>
                    </div>                    

                </div>


            </div>

            <div style="display: grid; width: 100%; margin-top: -10px;">


                <mat-card-content style="display: flex; margin-right: auto; margin-left: auto;">                                    
                    <!-- <div class="icon-container" *ngIf="offer.amenities.icons.is_king_room">
                        <img  src = "assets\svg\hotel-bed-outline.svg" alt="King Bed" class="icon-hotel"
                              style="margin: auto; display: block;"/> 
                        <p class="icon-label">King Room</p>                   
                    </div>

                    <div class="icon-container" *ngIf="offer.amenities.icons.is_view">
                        <img  src = "assets\svg\binoculars.svg" alt="Nice View" class="icon-hotel"
                              style="margin: auto; display: block;"/> 
                        <p class="icon-label">View</p>                    
                    </div>

                    <div class="icon-container" *ngIf="offer.amenities.icons.is_non_smoking">
                        <img  src = "assets\svg\non-smoking.svg" alt="Non Smoking" class="icon-hotel"
                              style="margin: auto; display: block;"/> 
                        <p class="icon-label">Non</p>                    
                    </div>

                    <div class="icon-container" *ngIf="offer.amenities.icons.is_large_room">
                        <img  src = "assets\svg\triangle-ruler.svg" alt="Large Room" class="icon-hotel"
                              style="  margin: auto; display: block; margin-top: 2px;"/> 
                        <p class="icon-label">{{offer.amenities.sqft}} SF</p>                    
                    </div>

                    <div class="icon-container" *ngIf="offer.amenities.icons.is_renovated">
                        <img  src = "assets\svg\star.svg" alt="Large Room" class="icon-hotel"
                              style="  margin: auto; display: block;"/> 
                        <p class="icon-label">Reno'd</p>                    
                    </div>     -->
                </mat-card-content>


                <!-- [ngClass]="{'card-content-expanded': (this.currentIndex == i && !this.collapsed)}" -->
                <div class="container" style=" margin-top: -18px; cursor: pointer; width: 100%;">
                    
                    <div [attr.id]="'contentContainer' + i"   
                        class="card-content-contracted-container"
     

                        (click)="toggleCollapse(i)">
                        <div style="height: 0px; margin-top: 20px;"></div>
                        <mat-card-content 
                            [attr.id]="'contentParagraph' + i"                         
                            class="card-content-paragraph hidden"
                            [ngClass]="this.hiddenStyle(i)"
                        >
                            {{offer.amenities.upsell_room_description}}
                        </mat-card-content>
                        
                        <mat-card-content 
                            [attr.id]="'contentLineHeight' + i"                         
                            class="card-content hidden"
                            >
                        </mat-card-content>



                        
                            <mat-card-content 
                            class="card-content"
                            *ngFor="let attr of offer.amenities.upsell_attributes.slice(0, this.currentIndex != i ? this.MIN_LIST_INDEX : this.max_list_index);let j = index"                            
                            [ngClass]="contentStyle(i,j)">
                
                                    <p *ngIf="this.togglAttrSpan(j + 0, i, attr)" 
                                      style="color: rgba(255, 255, 255, 0.60); display: list-item;"> 
                                        <span  *ngIf="this.togglAttrSpan(j + 2, i, attr)" style="color: white !important;">
                                        {{attr.substring(0, attr.indexOf(':'))}}</span>
                                        {{clickForMoreDetails(j, i + 0, attr)}}</p>                                
                    
                            </mat-card-content>


                        <mat-card-actions class="offer-actions" style="display: block;">                                
                        


                            <button 
                            class="button-request" 
                            style="width: 120px;  
                            transition: all 0.5s; 
                            margin-bottom: 10px;
                            margin-right: -2px; 
                            margin-left: auto;  
                            float: right;
                            height: auto;
                            "

                            mat-raised-button (click)="openModalTermsAndConditions(termsAndConditions, i)">REQUEST</button>
                        
                            <p class="card-header-price card-title-price " style="color: white; ">
                                <span style="text-decoration: line-through; color: rgba(255, 255, 255, 0.60); " >${{offer.bar_price}}</span> ${{offer.ofr_price}}/nt                        
                            </p>              

                        </mat-card-actions>

                    </div>


                    <!-- [style.margin-top] = "!collapsed && this.currentIndex == i ? requestButtonMargin.toString()+'px !important': MIN_REQ_BTN_MARGIN.toString()+'px'" -->


                </div>                
            </div>
        </div>
    </mat-card>
    
</div>


<div style="
    width: 85%;
    display: flex;
    margin: auto;
    margin-top: 15px;
    text-align: center;">
    <p style="
        text-align: left; 
        text-transform: uppercase; 
        color: white;
        display: flex;
        margin-left: auto; 
        margin-right: 0;">
        <span 
            style="color: white;" 
            class="button-link"
            (click)="this.navigateToOfferDeclined()"

            >NO THANKS
        </span>
        <span style="color: white;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> 
        <span style="color: white;" class="button-link" (click)="toggleSearch()">VIEW MORE &nbsp;<i class="fa fa-plus" style="color: #77b300"></i> </span>
    </p>
</div>


<!-- [style.height] = "!collapsed && this.currentIndex == i ? cardHeight.toString()+'px !important': MIN_CARD_HEGIHT.toString()+'px !important'" -->
<!-- <mat-card 
class="offer-card hidden"  
[ngStyle]="{'transition: all 0.5s;': !collapsed && this.currentIndex == i}"

style="height: auto !important;"
*ngFor="let offer of offers?.slice(0, this.numberOfOffersToShow);let i = index">


    <div class=""  [attr.id]="'content-' + i" style="height: auto;"  >
        <mat-card-content 
        *ngFor="let attr of offer.upsell_attributes.slice(0,offer.upsell_attributes.length);let j = index"      
        class="card-content "            
        [attr.id]="'content-' + i + '-' + j"                            
>                            
    <p style="color: rgba(255, 255, 255, 0.60)"> <span style="color: white !important;">{{attr.substring(0, attr.indexOf(':'))}}</span>
    {{attr.substring(attr.indexOf(':'), attr.length) }}</p>
    </mat-card-content>

    </div>

</mat-card> -->





<!-- <mat-expansion-panel hideToggle>
    <mat-expansion-panel-header>
      <mat-panel-title>
        This is the expansion title
      </mat-panel-title>
      <mat-panel-description>
        This is a summary of the content
      </mat-panel-description>
    </mat-expansion-panel-header>
    <p>This is the primary content of the panel.</p>
  </mat-expansion-panel>
     -->