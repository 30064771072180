<ngx-spinner [ngStyle]="this.sharedService.customStyle('spinnerTheme')" type="square-jelly-box"><h3 [ngStyle]="this.sharedService.customStyle('spinnerTheme')">Loading...</h3></ngx-spinner>
<div [ngStyle]="this.sharedService.customStyle('bodyTheme')" style="height: auto;"  *ngIf="{offerStatus: offerStatus$ | async, error: offerErrors$ | async} as data" >                        
    <div [hidden]="data.error ? true : false" >                
        <div *ngIf="data.offerStatus" style="width: 100vw; height: 100vh;">
            <mat-card [ngStyle]="this.sharedService.customStyle('cardTheme')"
                style="
                    
                    /* vertical center */
                    margin: 0;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    /* vertical center */
        
                    padding-left: 50px;
                    padding-right: 50px;
                    padding-bottom: 50px;
                    padding-top: 50px;
        
                    width: 20%;
                    min-width: 380px !important;
                    height: auto;
                    max-height: 221 !important;
                    font-size: large;
                    text-align: center;"
        
                    class="not-available-text"   
                >
                <p *ngIf="data.offerStatus.conf_num !== ''" [ngStyle]="this.sharedService.customStyle('cardTheme')">Confirmation number {{data.offerStatus.conf_num}} is {{data.offerStatus.upgrade_status | titlecase}}. {{data.offerStatus.upgrade_status === 'PENDING' ? 'Please check back later.' : ''}}</p>
                <p *ngIf="data.offerStatus.conf_num === ''" [ngStyle]="this.sharedService.customStyle('cardTheme')">Your reservation is {{data.offerStatus.upgrade_status | titlecase}}. {{data.offerStatus.upgrade_status === 'PENDING' ? 'Please check back later.' : ''}}</p>            
            </mat-card>
        </div>
    </div>
    <app-offer-not-available [hidden]="data.error ? false : true"></app-offer-not-available>                    
</div> 








