import { Component, OnInit } from '@angular/core';
import { SharedService } from '../../_services/shared.service';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { OfferService } from '../../_services/offer/offer.service';
import { ActivatedRoute, Router } from '@angular/router';
import { StatusDTO } from '../../model/statusDTO';
import { Observable, catchError, ignoreElements, of, shareReplay } from 'rxjs';
import { BusyService } from '../../_services/busy.service';
import { OfferNotAvailableComponent } from '../offer-not-available/offer-not-available.component';
import { NgxSpinnerModule } from 'ngx-spinner';


@Component({
  selector: 'app-offer-status',
  standalone: true,
  imports: [CommonModule,
            MatCardModule,
            OfferNotAvailableComponent,
            NgxSpinnerModule],
  templateUrl: './offer-status.component.html',
  styleUrl: './offer-status.component.css'
})
export class OfferStatusComponent implements OnInit {

  private propertyId: string = '';
  private offerAliasId: string = '';
  public offerStatus$!: Observable<StatusDTO>;
  public offerStatus!: StatusDTO;
  public offerErrors$: any;
  private isError: boolean = false;

  constructor(public sharedService: SharedService,
              private offerService: OfferService,
              private route: ActivatedRoute,
              private router: Router,
              private busyService: BusyService) { 
  }


  ngOnInit(): void {
    this.propertyId = this.route.snapshot.paramMap.get('prop_id') ? this.route.snapshot.paramMap.get('prop_id') as string : '';
    this.offerAliasId = this.route.snapshot.paramMap.get('alias_id') ? this.route.snapshot.paramMap.get('alias_id') as string : '';;
    this.offerStatus$ = this.getOfferStatus(this.offerAliasId, this.propertyId);

    this.offerErrors$ = this.offerStatus$.pipe(
                                          shareReplay(1),
                                          ignoreElements(),
                                          catchError((err) => {
                                            console.log(err);
                                            return of(err)})
                                        );

  }
  
  getOfferStatus(offerAliasId: string, propertyId: string): Observable<StatusDTO> {
    return this.offerStatus$ = this.offerService.getOfferStatus(offerAliasId, propertyId).pipe(shareReplay(1));
  }

}
