import { Routes } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { OfferConfirmationComponent } from './components/offer-confirmation/offer-confirmation.component';
import { OfferDeclinedComponent } from './components/offer-declined/offer-declined.component';
import { OfferNotAvailableComponent } from './components/offer-not-available/offer-not-available.component';
import { OfferStatusComponent } from './components/offer-status/offer-status.component';
import { OfferTokenValidationComponent } from './components/offer-token-validation/offer-token-validation.component';

// Dynamic Routes
// https://www.google.com/search?q=ANGULAR+DYNAMIC+ROUTE+BASED+ON+UUID&oq=ANGULAR+DYNAMIC+ROUTE+BASED+ON+UUID&gs_lcrp=EgZjaHJvbWUyBggAEEUYOTIGCAEQRRhA0gEJMTYwMDJqMGo3qAIAsAIA&sourceid=chrome&ie=UTF-8#fpstate=ive&vld=cid:96b46bc0,vid:nLxy0sqxhAc,st:0


export const routes: Routes = [
    { 'path': '', component:HomeComponent},
    { 'path': 'offer/:alias_id/:prop_id', component:HomeComponent},
    { 'path': 'offerStatus/:alias_id/:prop_id', component:OfferStatusComponent},
    { 'path': 'offerConfirmation/:alias_id/:prop_id', component: OfferConfirmationComponent},
    { 'path': 'offerTokenValidation/:alias_id/:prop_id', component: OfferTokenValidationComponent},    
    { 'path': 'offerDeclined', component: OfferDeclinedComponent},
    
    { 'path': 'offerNotAvailable', component: OfferNotAvailableComponent},
    
    { 'path': '**',
        component: OfferNotAvailableComponent
    }

];

// routerLink="offer/<alias_id uuid>"