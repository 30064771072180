import { Injectable, Renderer2, RendererStyleFlags2, RendererFactory2, inject } from '@angular/core';
import { Offer } from '../model/offer';
import { BsModalService } from 'ngx-bootstrap/modal';
import { CustomStyle } from '../model/custom-style';

@Injectable({
  providedIn: 'root'
})
export class SharedService {


  public offer!:Offer; // set by offer-card-list-responsive component
  public offers!:Offer[]; // set by home component
  public currentIndex:number = -1;
  public currentOfferIndex:number = -1;
  public modalService!: BsModalService;


  public config = {
    animated: true,
    keyboard: true,
    backdrop: false,
    ignoreBackdropClick: false,
    class: 'modal-dialog-centered modal-photo-gallery modal-lg' 
  };

  public isLightTheme: boolean = true;
  public isDarkTheme: boolean = false;
  public isCustomTheme: boolean = false;
  public iconColorBlack: boolean = true;
  public iconColor: string = '-black';
  public iconColorClose: string = '';

  private selectedTheme!: CustomStyle;
  //#FBF5DF 'Roboto, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif'
  private bodyLightTheme = {'color': 'black', 'background-color': '#f6f6f9', 'font-family': 'Verdana, Geneva, Tahoma, sans-serif'}
  private bodyDarkTheme = {'color': 'white', 'background-color': '#161616', 'font-family': 'Verdana, Geneva, Tahoma, sans-serif'}
  private bodyCustomTheme = this.bodyDarkTheme

  private spinnerLightTheme = {'color': '#161616'}
  private spinnerDarkTheme = {'color': '#f6f6f9'}
  private spinnerCustomTheme = this.spinnerDarkTheme

  private bodyModalLightTheme = {'color': 'white', 'background-color': '#161616', 'font-family': 'Verdana, Geneva, Tahoma, sans-serif'}
  private bodyModalDarkTheme = {'color': 'black', 'background-color': '#f1f1f4', 'font-family': 'Verdana, Geneva, Tahoma, sans-serif'}
  private bodyModalCustomTheme = this.bodyModalDarkTheme

  private scrollModalLightTheme = {'scrollbar-color': '#5a5959 #2d2d2d'}
  private scrollModalDarkTheme = {}
  private scrollModalCustomTheme = this.scrollModalDarkTheme

  // #FBFCF8
  private cardLightTheme = {'color': 'black', 'background-color': '#f1f1f4', 'font-family': 'inherit'}
  private cardDarkTheme = {'color': 'white', 'background-color': '#212121', 'font-family': 'inherit'}
  private cardCustomTheme = this.cardDarkTheme;

  private txtGreetingLightTheme = {'color': 'black', 'font-family': 'inherit'}
  private txtGreetingDarkTheme = {'color': '#ffffff99', 'font-family': 'inherit'}
  private txtGreetingCustomTheme = this.txtGreetingDarkTheme;

  private txtGreetingBrightLightTheme = {'color': 'black', 'font-weight': 'bold', 'font-family': 'inherit'}
  private txtGreetingBrightDarkTheme = {'color': 'white', 'font-family': 'inherit'}
  private txtGreetingBrightCustomTheme = this.txtGreetingBrightDarkTheme;
  // #a41b32
  private cardHeaderLightTheme = {'color': 'black', 'background-color': '#0d6efd', 'font-family': 'inherit'}
  private cardHeaderDarkTheme = {'color': 'white', 'background-color': '#2d2d2d', 'font-family': 'inherit'}
  private cardHeaderCustomTheme = this.cardHeaderDarkTheme;
  //  #333333
  private buttonRequestLightTheme = {'color': 'whitesmoke',  'background-color': '#dd1d54', 'font-family': 'inherit'}
  private buttonRequestDarkTheme = {'color': 'whitesmoke', 'background-color': '#dd1d54', 'font-family': 'inherit'}
  private buttonRequestCustomTheme = this.buttonRequestDarkTheme;

  private cardContentLightTheme = {'color': 'black', 'font-family': 'inherit'}
  private cardContentDarkTheme = {'color': 'white', 'font-family': 'inherit'}
  private cardContentCustomTheme = this.cardContentDarkTheme;

  private iconGroupLightTheme = {'color': 'black', 'font-family': 'inherit'}
  private iconGroupDarkTheme = {'color': 'white', 'font-family': 'inherit'}
  private iconGroupCustomTheme = this.iconGroupDarkTheme;

  private txtLightTheme = {'color': 'black', 'font-family': 'inherit'}
  private txtDarkTheme = {'color': 'white', 'font-family': 'inherit'}
  private txtCustomTheme = this.txtDarkTheme;

  private txtModalLightTheme = {'color': 'white', 'font-family': 'inherit'}
  private txtModalDarkTheme = {'color': 'black', 'font-family': 'inherit'}
  private txtModalCustomTheme = this.txtModalDarkTheme; 

  private barPriceModalLightTheme = {'color': 'rgba(255, 255, 255, 0.60)', 'font-family': 'inherit'}
  private barPriceModalDarkTheme = {'color': 'black', 'font-family': 'inherit'}
  private barPriceModalCustomTheme = this.barPriceModalDarkTheme; 

  private priceModalLightTheme = {'color': 'white', 'font-family': 'inherit', 'font-weight': 'bold'}
  private priceModalDarkTheme = {'color': 'black', 'font-family': 'inherit', 'font-weight': 'bold'}
  private priceModalCustomTheme = this.priceModalDarkTheme; 


  private barPriceLightTheme = {'color': 'black', 'font-family': 'inherit'}
  private barPriceDarkTheme = {'color': 'rgba(255, 255, 255, 0.60)', 'font-family': 'inherit'}
  private barPriceCustomTheme = this.barPriceDarkTheme; 

  private priceLightTheme = {'color': 'black', 'font-family': 'inherit', 'font-weight': 'bold'}
  private priceDarkTheme = {'color': 'white', 'font-family': 'inherit', 'font-weight': 'bold'}
  private priceCustomTheme = this.priceDarkTheme; 

  private formLightTheme = {'color': 'black', 'background-color': '#f1f1f4', 'font-family': 'inherit'}
  private formDarkTheme = {'color': 'white', 'background-color': '#212121', 'font-family': 'inherit'}
  private formCustomTheme = this.formDarkTheme;

  private txtDimLightTheme = {'color': '#00000099', 'font-family': 'inherit'}
  private txtDimDarkTheme = {'color': 'ffffff99', 'font-family': 'inherit'}
  private txtDimCustomTheme = this.txtDimDarkTheme;

  // https://dev.to/manthanank/what-is-renderer2-how-to-use-it-in-angular-project-5l1
 

  public renderer!: Renderer2;
  constructor(rendererFactory: RendererFactory2) {                 
                this.renderer = rendererFactory.createRenderer(null, null); // this.renderer = inject(Renderer2);               
                this.selectTheme();
                this.selectIconColor();
                this.setBodyBackgroundColor();
  }
  
  toggleTheme(){
    this.isDarkTheme = !this.isDarkTheme;
    this.isLightTheme = !this.isDarkTheme;
    this.selectIconColor();
    this.selectTheme();
    this.setBodyBackgroundColor();
  }

  getStyleValue(styleGroupName: string, styleName: string): string{
    var bodyTheme = this.customStyle(styleGroupName);
    let name = styleName;
    let key = name as keyof typeof bodyTheme;
    return bodyTheme[key];    
  }

  setBodyBackgroundColor(){
    var bodyTheme = this.customStyle('bodyTheme');
    let name = 'background-color';
    let key = name as keyof typeof bodyTheme;
    if (typeof document !== 'undefined') {
      this.renderer.setStyle(document.body, name, bodyTheme[key], RendererStyleFlags2.Important + RendererStyleFlags2.DashCase);
    }

  }

  selectIconColor(){
    if(this.isLightTheme) {
      this.iconColorBlack = true;
      this.iconColor='-black';
      this.iconColorClose=''
    } else if(this.isDarkTheme) {
      this.iconColorBlack = false;
      this.iconColor='';
      this.iconColorClose='-black';
    }
  }

  selectTheme(){
    if(this.isLightTheme){
      this.selectedTheme = {"bodyTheme": this.bodyLightTheme, 
                            "spinnerTheme": this.spinnerLightTheme,
                            "bodyModalTheme": this.bodyModalLightTheme,
                            "scrollModalTheme":  this.scrollModalLightTheme,                            
                            "cardTheme": this.cardLightTheme, 
                            "txtGreetingTheme": this.txtGreetingLightTheme, 
                            "txtGreetingBrightTheme": this.txtGreetingBrightLightTheme, 
                            "cardHeaderTheme": this.cardHeaderLightTheme, 
                            "buttonRequestTheme": this.buttonRequestLightTheme, 
                            "cardContentTheme": this.cardContentLightTheme,
                            "iconGroupTheme": this.iconGroupLightTheme, 
                            "txtTheme": this.txtLightTheme,
                            "txtModalTheme": this.txtModalLightTheme,
                            "barPriceModalTheme": this.barPriceModalLightTheme,
                            "priceModalTheme": this.priceModalLightTheme,
                            "barPriceTheme": this.barPriceLightTheme,
                            "priceTheme": this.priceLightTheme,                                                            
                            "txtDimTheme": this.txtDimLightTheme, 
                            "formTheme": this.formLightTheme};
    } else if(this.isDarkTheme){
      this.selectedTheme = {"bodyTheme": this.bodyDarkTheme,
                            "spinnerTheme": this.spinnerDarkTheme,
                            "bodyModalTheme": this.bodyModalDarkTheme,
                            "scrollModalTheme":  this.scrollModalDarkTheme,
                            "cardTheme": this.cardDarkTheme, 
                            "txtGreetingTheme": this.txtGreetingDarkTheme, 
                            "txtGreetingBrightTheme": this.txtGreetingBrightDarkTheme, 
                            "cardHeaderTheme": this.cardHeaderDarkTheme,
                            "buttonRequestTheme": this.buttonRequestDarkTheme, 
                            "cardContentTheme": this.cardContentDarkTheme,
                            "iconGroupTheme": this.iconGroupDarkTheme, 
                            "txtTheme": this.txtDarkTheme, 
                            "txtModalTheme": this.txtModalDarkTheme,
                            "barPriceModalTheme": this.barPriceModalDarkTheme,
                            "priceModalTheme": this.priceModalDarkTheme,    
                            "barPriceTheme": this.barPriceDarkTheme,
                            "priceTheme": this.priceDarkTheme,                                                                           
                            "txtDimTheme": this.txtDimDarkTheme,
                            "formTheme": this.formDarkTheme};
    } else if(this.isCustomTheme) {
      this.selectedTheme = {"bodyTheme": this.bodyCustomTheme, 
                            "spinnerTheme": this.spinnerCustomTheme,      
                            "bodyModalTheme": this.bodyModalCustomTheme,
                            "scrollModalTheme":  this.scrollModalCustomTheme,
                            "cardTheme": this.cardCustomTheme, 
                            "txtGreetingTheme": this.txtGreetingCustomTheme, 
                            "txtGreetingBrightTheme": this.txtGreetingBrightCustomTheme, 
                            "cardHeaderTheme": this.cardHeaderCustomTheme,
                            "buttonRequestTheme": this.buttonRequestCustomTheme, 
                            "cardContentTheme": this.cardContentCustomTheme,
                            "iconGroupTheme": this.iconGroupCustomTheme, 
                            "txtTheme": this.txtCustomTheme,
                            "txtModalTheme": this.txtModalCustomTheme,
                            "barPriceModalTheme": this.barPriceModalCustomTheme,
                            "priceModalTheme": this.priceModalCustomTheme,     
                            "barPriceTheme": this.barPriceCustomTheme,
                            "priceTheme": this.priceCustomTheme,                                                      
                            "txtDimTheme": this.txtDimCustomTheme,
                            "formTheme": this.formCustomTheme};
    } else {
      this.selectedTheme = {"bodyTheme": this.bodyLightTheme,
                            "spinnerTheme": this.spinnerCustomTheme,
                            "bodyModalTheme": this.bodyModalCustomTheme,
                            "scrollModalTheme":  this.scrollModalCustomTheme,
                            "cardTheme": this.cardLightTheme, 
                            "txtGreetingTheme": this.txtGreetingLightTheme, 
                            "txtGreetingBrightTheme": this.txtGreetingBrightLightTheme, 
                            "cardHeaderTheme": this.cardHeaderLightTheme, 
                            "buttonRequestTheme": this.buttonRequestLightTheme, 
                            "cardContentTheme": this.cardContentLightTheme,
                            "iconGroupTheme": this.iconGroupLightTheme, 
                            "txtTheme": this.txtLightTheme,
                            "txtModalTheme": this.txtModalCustomTheme,
                            "barPriceModalTheme": this.barPriceModalCustomTheme,
                            "priceModalTheme": this.priceModalCustomTheme,   
                            "barPriceTheme": this.barPriceLightTheme,
                            "priceTheme": this.priceCustomTheme,                                                              
                            "txtDimTheme": this.txtDimCustomTheme, 
                            "formTheme": this.formLightTheme};
    }
  }

  public customStyle(name: string){
    let key = name as keyof typeof this.selectedTheme;
    return this.selectedTheme[key];
  }

  public customStyleMulti(names: string[]){
    let styles = {}
    for(let name of names){
      let key = name as keyof typeof this.selectedTheme;
      styles = Object.assign({}, styles, this.selectedTheme[key]);
    }
    return styles;
  }

  setSelectedOffer(index: number, offers: Offer[], expandContent: boolean){    
    if(!offers) return;
    
    if(expandContent) { // Prevents auto expand if user selects request without viewing offer details
      this.currentIndex = index;
    }

    this.currentOfferIndex = index;
    this.offer = offers[index];
  }

}
