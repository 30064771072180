import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, ElementRef, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { Offer } from '../../../model/offer';
import { ResponsiveMode } from '../../../enum/responsiveModes';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { SharedService } from '../../../_services/shared.service';
import Utils from '../../../_utils/utils';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { OfferPhotoTermsComponent } from '../../offer-photo-terms/offer-photo-terms.component';
import { Observable, fromEvent, map, shareReplay } from 'rxjs';


@Component({
  selector: 'app-offer-content',
  standalone: true,
  imports: [CommonModule, MatCardModule, OfferPhotoTermsComponent,],
  providers: [BsModalService],
  templateUrl: './offer-content.component.html',
  styleUrl: './offer-content.component.css'
})
export class OfferContentComponent implements OnInit, AfterViewInit {


  @Input()
  offers!: Offer[] | null;
  @Input()
  offer!: Offer | null;
  @Input()
  index: number = 0;
  @Input()
  viewMoreToggle: boolean = false;

  mouseOverContent$: Observable<MouseEvent> = new Observable<MouseEvent>(); 
  mouseOverButton$: Observable<MouseEvent> = new Observable<MouseEvent>(); 
  mouseOutButton$: Observable<MouseEvent> = new Observable<MouseEvent>(); 
  
  viewMode: string = ResponsiveMode.horizontal;
  modalRef!: BsModalRef;
  responsiveMode = ResponsiveMode;
  collapsed:boolean = true;
  MIN_LIST_INDEX:number = 2;
  max_list_index:number = 2;

  @ViewChild('cardContent') content!: ElementRef;
  @ViewChild('cardButton') button!: ElementRef;

  overButton: boolean = false;


  constructor(private responsive: BreakpointObserver,
              private modalService: BsModalService, 
              public sharedService: SharedService) {
  }


  ngAfterViewInit(): void {

    // uncomment if you want content to auto expand on hover

    // this.mouseOverContent$ = fromEvent<MouseEvent>(this.content.nativeElement, 'mouseover')
    //   .pipe(
    //     shareReplay(1),
    //     map((event) =>  event))
      
    // this.mouseOverContent$
    //   .subscribe(event =>  {
    //         if(this.index !== this.sharedService.currentIndex && !this.overButton){
    //           this.toggleCollapse(this.index);
    //         }          
    //     });

    // this.mouseOverButton$ = fromEvent<MouseEvent>(this.button.nativeElement, 'mouseover')
    //   .pipe(
    //     shareReplay(1),
    //     map((event) =>  event));


    // this.mouseOverButton$
    //     .subscribe(event =>  {
    //         this.overButton = true;       
    //       });

    // this.mouseOutButton$ = fromEvent<MouseEvent>(this.button.nativeElement, 'mouseout')
    //     .pipe(
    //       shareReplay(1),
    //       map((event) =>  event));

    // this.mouseOutButton$
    //       .subscribe(event =>  {
    //           this.overButton = false;
    //         });
  }


  ngOnInit(): void {

    this.responsive.observe([
      Breakpoints.HandsetPortrait,
    ])
      .subscribe(result => {
        const breakpoints = result.breakpoints;
        if(breakpoints[Breakpoints.HandsetPortrait]){
          this.viewMode = ResponsiveMode.vertical;
        }else {
          this.viewMode = ResponsiveMode.horizontal;
        }          
      });
  }

  openModalTermsAndConditions(openTemplate: TemplateRef<any>, index: number) {

    if(this.offers) {
      this.sharedService.setSelectedOffer(index, this.offers, false);
    }
    
    if(this.modalRef){
      this.modalRef.hide();
    }

    setTimeout(() => {
      this.modalRef = this.modalService.show(openTemplate, this.sharedService.config);
    }, 0)

  }


  toggleCollapse(index: number) {
      
    if(!this.overButton){
      if(index !== this.sharedService.currentIndex){
        this.collapsed = false;
      }else{
        this.collapsed = !this.collapsed;
      }
      this.setMaxListIndex(this.collapsed, index);
      if(this.offers){
        this.sharedService.setSelectedOffer(index, this.offers, true);
      }
    }
    
  }

  tapForDetails(i: number, offer: Offer): string[]{
    
    let amenities = offer.amenities.upsell_attributes.slice(0, this.sharedService.currentIndex != i ? this.MIN_LIST_INDEX : this.max_list_index)

    const length = amenities.length;
   
    if(this.sharedService.currentIndex != i || (this.sharedService.currentIndex != i && this.collapsed) || (this.sharedService.currentIndex === i && this.collapsed)){
      amenities.push('Tap for details...');
    }

    if((this.index !== this.sharedService.currentIndex && this.collapsed) || (this.index === this.sharedService.currentIndex && this.collapsed) ){
      for (let i = 0; i < 2 - length; i++) {
        amenities.push('');
      }       
    } else {
      amenities = amenities.filter(a => a !== '');
    }

    return amenities
  }

  contentStyle(i: number, j:number): {[key: string]: boolean} {
    return {
      'even-more-opacity': (j == 0 && this.sharedService.currentIndex !== i) || (j == 0 && this.sharedService.currentIndex === i && this.collapsed), 
      'more-opacity': (j == 1 && this.sharedService.currentIndex !== i) || (j == 1 && this.sharedService.currentIndex === i && this.collapsed), 
      'some-opacity': (j == 2  && this.sharedService.currentIndex !== i) || (j == 2 && this.sharedService.currentIndex === i && this.collapsed),
      // 'card-details-txt' : (j == 3  && this.currentIndex !== i) || (j == 3 && this.currentIndex === i && this.collapsed),
      'card-content-expanded': this.sharedService.currentIndex === i && !this.collapsed , 
      // 'card-content-contracted': this.currentIndex != i && this.collapsed || this.currentIndex === i && this.collapsed
    }
  }

  setMaxListIndex(collapsed: boolean, index: number){
    if(!this.offers) return;
    if(collapsed) {
      this.max_list_index = this.MIN_LIST_INDEX;
    }else{
      this.max_list_index = this.offers[index].amenities.upsell_attributes.length;
    }
  }

  responsiveStyle(styleObj: {[key: string]: string}): {[key: string]: boolean} {   
    return Utils.responsiveStyle(this.viewMode, styleObj);
  }
    
  offerIndex(index: number, viewMoreToggle: boolean, numOffers: number): string {
    return Utils.offerIndex(index, viewMoreToggle, numOffers);
  }

}
