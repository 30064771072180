import { Component } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { SharedService } from '../../_services/shared.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-offer-not-available',
  standalone: true,
  imports: [CommonModule, MatCardModule],
  templateUrl: './offer-not-available.component.html',
  styleUrl: './offer-not-available.component.css'
})
export class OfferNotAvailableComponent {

  constructor(public sharedService: SharedService){

  }

}
