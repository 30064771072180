
<!-- -->
<link rel="preconnect" href="https://d3cd5omdchfki3.cloudfront.net">
<div [ngClass]="{'image-shake': index === 0}" *ngIf="offer" >
    <div class="box" [ngClass]="this.responsiveStyle({ 'vertical': 'box-handset', 'horizontal': 'box-desktop'})">
        <div class="image-hotel-background" [ngClass]="this.responsiveStyle({ 'vertical': 'image-hotel-background-handset', 'horizontal': 'image-hotel-background-desktop'})"                  
            [ngStyle]="{'background-image': 'url(' + offer.amenities.room_photo_url + ')'}">
            <div class="image-hotel-backdrop" [ngClass]="this.responsiveStyle({ 'vertical': 'image-hotel-backdrop-handset', 'horizontal': ''})">                        
            </div>
        </div>
        <div>                    
            <img class="image-hotel" [ngClass]="this.responsiveStyle({ 'vertical': 'image-hotel-handset', 'horizontal': 'image-hotel-desktop'})" style="object-fit: cover;" 
                [ngSrc]="offer.amenities.room_photo_url" fill priority>
            <div class="ribbon-red" [hidden]="index != 0">
                <div class="txt-red">Hot Deal!</div>
            </div>
            <div class="ribbon-blue" [hidden]="!offer.is_popular">
                <div class="txt-blue">Popular!</div>
            </div>                    
        </div>
    </div>
</div>

