import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { Offer } from '../../model/offer';
import Utils from '../../_utils/utils';
import { ResponsiveMode } from '../../enum/responsiveModes';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { SharedService } from '../../_services/shared.service';

@Component({
  selector: 'app-offer-card-greeting',
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
  ],
  templateUrl: './offer-card-greeting.component.html',
  styleUrl: './offer-card-greeting.component.css'
})
export class OfferCardGreetingComponent implements OnInit {

  @Input()
  offers!: Offer[] | null;

  viewMode: string = ResponsiveMode.horizontal;

  constructor(private responsive: BreakpointObserver,
              public sharedService: SharedService) {
  }

  ngOnInit(): void {

    this.responsive.observe([
      Breakpoints.HandsetPortrait,
    ])
      .subscribe(result => {

        const breakpoints = result.breakpoints;

        if(breakpoints[Breakpoints.HandsetPortrait]){
          this.viewMode = ResponsiveMode.vertical;
        }else {
          this.viewMode = ResponsiveMode.horizontal;
        }          

      });
  }

  responsiveStyle(styleObj: {[key: string]: string}): {[key: string]: boolean} {   
    return Utils.responsiveStyle(this.viewMode, styleObj)
  } 

}
