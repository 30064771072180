import { Component, Input, OnInit } from '@angular/core';
import { Offer } from '../../../model/offer';
import { ResponsiveMode } from '../../../enum/responsiveModes';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { SharedService } from '../../../_services/shared.service';
import Utils from '../../../_utils/utils';
import { MatCardModule } from '@angular/material/card';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-offer-icon',
  standalone: true,
  imports: [CommonModule, MatCardModule],
  templateUrl: './offer-icon.component.html',
  styleUrl: './offer-icon.component.css'
})
export class OfferIconComponent implements OnInit {

  @Input()
  offer!: Offer | null;
  @Input()
  offers!: Offer[] | null;
  @Input()
  viewMode: string = ResponsiveMode.horizontal;
  @Input()
  viewMoreToggle: boolean = false;
  @Input()
  index: number = 0;

  responsiveMode = ResponsiveMode;

  constructor(private responsive: BreakpointObserver,
              public sharedService: SharedService) {
  }

  ngOnInit(): void {

    this.responsive.observe([
      Breakpoints.HandsetPortrait,
    ])
      .subscribe(result => {

        const breakpoints = result.breakpoints;

        if(breakpoints[Breakpoints.HandsetPortrait]){
          this.viewMode = ResponsiveMode.vertical;
        }else {
          this.viewMode = ResponsiveMode.horizontal;
        }          

      });
  }

  responsiveStyle(styleObj: {[key: string]: string}): {[key: string]: boolean} {   
    return Utils.responsiveStyle(this.viewMode, styleObj)
  } 

  offerIndex(index: number, viewMoreToggle: boolean, numOffers: number): string {
    return Utils.offerIndex(index, viewMoreToggle, numOffers);
  }

}
