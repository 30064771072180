import { Component, OnInit } from '@angular/core';
import { SharedService } from '../../_services/shared.service';
import { OfferService } from '../../_services/offer/offer.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerModule } from 'ngx-spinner';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { OfferNotAvailableComponent } from '../offer-not-available/offer-not-available.component';
import { CodeInputModule } from 'angular-code-input';
import { AuthService } from '../../_services/auth.service';
import { map, mergeMap } from 'rxjs';

@Component({
  selector: 'app-offer-token-validation',
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    OfferNotAvailableComponent,
    NgxSpinnerModule,
    CodeInputModule
  ],
  templateUrl: './offer-token-validation.component.html',
  styleUrl: './offer-token-validation.component.css'
})
export class OfferTokenValidationComponent implements OnInit {


  private propertyId: string = '';
  private offerAliasId: string = '';

  constructor(public sharedService: SharedService,
              private authService: AuthService,
              private offerService: OfferService,              
              private router: Router, 
              private route: ActivatedRoute) { 
  }
  ngOnInit(): void {
    this.propertyId = this.route.snapshot.paramMap.get('prop_id') ? this.route.snapshot.paramMap.get('prop_id') as string : '';
    this.offerAliasId = this.route.snapshot.paramMap.get('alias_id') ? this.route.snapshot.paramMap.get('alias_id') as string : '';    
  }
  // this called every time when user changed the code
  onCodeChanged(code: string) {
  }
  
  invalidCode: boolean = false;
  // this called only if user entered full code
  onCodeCompleted(code: string) {

    const validateRequest$ = this.authService.validateRequest(this.sharedService.offer, code);
    const acceptOffer$ = this.offerService.setOfferAccepted(this.sharedService.offer).pipe(map(res => this.navigateToOfferConfirmation()));     

    validateRequest$.pipe(
      mergeMap(res => 
        {
          if(res['data'] === true) {
            return acceptOffer$; 
          } else {
            this.invalidCode = true;
          }
          return res;
        }
      )).subscribe();
  }

  navigateToOfferConfirmation(){
    this.router.navigate(['/offerConfirmation/' + this.offerAliasId + '/' + this.propertyId], { relativeTo: this.route })
  }
}
