import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { SharedService } from '../../_services/shared.service';
import { MatCardModule } from '@angular/material/card';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, catchError, ignoreElements, map, of, shareReplay } from 'rxjs';
import { ConfirmationDTO } from '../../model/confirmationDTO';
import { OfferService } from '../../_services/offer/offer.service';
import { OfferNotAvailableComponent } from '../offer-not-available/offer-not-available.component';
import { NgxSpinnerModule } from 'ngx-spinner';


@Component({
  selector: 'app-offer-confirmation',
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    OfferNotAvailableComponent,
    NgxSpinnerModule
  ],
  templateUrl: './offer-confirmation.component.html',
  styleUrl: './offer-confirmation.component.css'
})
export class OfferConfirmationComponent implements OnInit {

  private propertyId: string = '';
  private offerAliasId: string = '';
  public offerConfirmation$!: Observable<ConfirmationDTO>;
  public offerConfirmation!: ConfirmationDTO;
  public offerErrors$: any;

  constructor(public sharedService: SharedService,
              private offerService: OfferService,              
              private router: Router, 
              private route: ActivatedRoute) { 
  }


  ngOnInit(): void {

    this.propertyId = this.route.snapshot.paramMap.get('prop_id') ? this.route.snapshot.paramMap.get('prop_id') as string : '';
    this.offerAliasId = this.route.snapshot.paramMap.get('alias_id') ? this.route.snapshot.paramMap.get('alias_id') as string : '';
    this.offerConfirmation$ = this.getConfrimationInfo(this.offerAliasId, this.propertyId);

    this.offerErrors$ = this.offerConfirmation$.pipe(
                                          shareReplay(1),
                                          ignoreElements(),
                                          catchError((err) => {
                                            console.log(err);
                                            return of(err)})
                                        );

  }
  
  getConfrimationInfo(offerAliasId: string, propertyId: string): Observable<ConfirmationDTO> {
    return this.offerConfirmation$ = this.offerService.getConfrimationInfo(offerAliasId, propertyId).pipe(shareReplay(1));
  }

  navigateToOfferStatus(){
    this.router.navigate(['/offerStatus/' + this.offerAliasId + '/' + this.propertyId ], { relativeTo: this.route })
  }

}
