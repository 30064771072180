
<mat-card *ngIf="this.sharedService.offers" style="height: 255px !important;" [ngStyle]="this.sharedService.customStyle('cardTheme')" [ngClass]="this.responsiveStyle({ 'vertical': 'offer-card-greeting-handset', 'horizontal': 'offer-card',})">
    <div style="margin-left: auto; margin-right: 10px; float: right; margin-top: 10px; height: 35px; width: 35px;
                cursor: pointer;" (click)="this.sharedService.toggleTheme()">
        
            <img [hidden]="this.sharedService.isLightTheme" style="height: inherit; width: inherit;"                          
            src='{{ "assets/svg/moon" + this.sharedService.iconColor + ".svg" }}'/>
            <img [hidden]="this.sharedService.isDarkTheme" style="height: inherit; width: inherit;"                          
            src='{{ "assets/svg/sun" + this.sharedService.iconColor + ".svg" }}'/>
        

    </div>
    <div *ngIf="this.sharedService.offers.length > 0" [ngStyle] = "this.sharedService.customStyle('txtGreetingTheme')"  
        [ngClass]="this.responsiveStyle({ 'vertical': 'container card-content-greeting-handset', 'horizontal': 'card-content-greeting',})">
        <div style="display: flex;"  >
            <p  style="text-transform: capitalize;"
            [ngClass]="this.responsiveStyle({ 'vertical': 'greeting-txt-handset', 'horizontal': 'greeting-txt',})">
            Hi {{this.sharedService.offers[0].guest_fname == null ? '' : this.sharedService.offers[0].guest_fname | titlecase}}!</p>

        </div>
        

        <div style="display: flex;" >
            <p [ngClass]="this.responsiveStyle({ 'vertical': 'greeting-txt-handset', 'horizontal': 'greeting-txt',})">
                You are currently booked at the {{this.sharedService.offers[0].prop_name == null ? '' : this.sharedService.offers[0].prop_name.toLowerCase().replace('the','') | titlecase}} into a 
                <span [ngStyle] = "this.sharedService.customStyle('txtGreetingBrightTheme')">{{ this.sharedService.offers[0].base_room_category_name == null ? '' : this.sharedService.offers[0].base_room_category_name | titlecase}}</span> on {{this.sharedService.offers[0].dt_arrival == null ? '' : this.sharedService.offers[0].dt_arrival | date:'fullDate'}} under confirmation number {{this.sharedService.offers[0].conf_num  == null ? '' : this.sharedService.offers[0].conf_num}}.</p>
        </div>
        <p [ngClass]="this.responsiveStyle({ 'vertical': 'greeting-txt-handset', 'horizontal': 'greeting-txt',})">
            We have some premium rooms available over these dates and would like to extend this personal upgrade offer to you. Reserve now for no upfront charge while rooms last!</p>
    </div>    
</mat-card>