import { HttpInterceptorFn } from '@angular/common/http';
import { BusyService } from '../_services/busy.service';
import { inject } from '@angular/core';
import { finalize } from 'rxjs';


// https://www.google.com/search?q=angular+17+adding+an+inteceptor&oq=angular+17+adding+an+inteceptor&gs_lcrp=EgZjaHJvbWUyBggAEEUYOTIGCAEQRRhA0gEJNzM0MGowajE1qAIAsAIA&sourceid=chrome&ie=UTF-8#fpstate=ive&vld=cid:fd2fc850,vid:hy_8JsOIVug,st:0
// https://www.npmjs.com/package/ngx-spinner
// https://napster2210.github.io/ngx-spinner/

export const LoadingInterceptor: HttpInterceptorFn = (req, next) => {

  let busyService = inject(BusyService);
  busyService.busy();

  

  return next(req).pipe(
    finalize(() => {
      busyService.idle();
    })
  );
};
