import { Component, ElementRef, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Offer } from '../../model/offer';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCommonModule } from '@angular/material/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {FormsModule} from '@angular/forms';
import { ModalModule, BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { GalleryItem, GalleryModule, ImageItem } from 'ng-gallery';
import { NgSelectModule } from '@ng-select/ng-select';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { SharedService } from '../../_services/shared.service';
import { Observable, map } from 'rxjs';
import { OfferService } from '../../_services/offer/offer.service';
import {NgxSpinnerModule } from 'ngx-spinner';



@Component({
  selector: 'app-offer-card-list',
  standalone: true,
  imports: [
    CommonModule,  
    MatTabsModule,
    MatButtonModule,
    MatCommonModule,
    MatCardModule,
    MatExpansionModule,
    MatInputModule,
    MatFormFieldModule,
    FormsModule,
    ModalModule,
    GalleryModule,
    NgSelectModule,
    RouterLink,
    NgxSpinnerModule
  ],
  providers: [BsModalService],
  templateUrl: './offer-card-list.component.html',
  styleUrl: './offer-card-list.component.css'
})
export class OfferCardListComponent implements OnInit, OnDestroy {


  // searchOffers(offer_alias_id: string): Observable<Offer[]>{

  //   return this.offers$ = this.offerService.getOfferFromAliasTemp(offer_alias_id)
  //     .pipe(
  //       map(offers => (offers as Offer[]))
  //       , debounceTime(400)
  //   );

  // }

  @Input()
  offers!: Offer[] | null;

  flg_default_offer: boolean = true;
  numberOfOffersToShow = 3;
  primaryOffers!: Offer[] | null;
  collapsed:boolean = true;
  currentIndex:number = -1;
  MIN_LIST_INDEX:number = 4;
  max_list_index:number = 4;
  view_more_toggle:boolean = false;

  images: GalleryItem[] = [];
  selected_offer!: Offer;


  @ViewChild('searchInput') searchInput!: ElementRef;

  modalActive: boolean = false;

  modalRef!: BsModalRef;
  config = {
    animated: true,
    keyboard: true,
    backdrop: false,
    ignoreBackdropClick: false,
    class: 'modal-dialog-centered modal-photo-gallery modal-lg' 

  };

  modalClose$!: Observable<boolean>;
  modalOpen$!: Observable<boolean>;

  constructor(
    public sharedService: SharedService,
    public modalService: BsModalService,
    private offerService: OfferService,  
    private router: Router, 
    private route: ActivatedRoute
    ) {}


    ngOnDestroy(): void {
      this.closeAllModals();
    }

    routeListen$!: Observable<any>;

    guest_fname:string = '';
    guest_lname:string = '';
    dt_arrival!:Date;
    prop_name:string = '';
    base_room_category:string ='';
    conf_num:string ='';

    ngOnInit(): void {

      this.routeListen$ = this.router.events;

      this.routeListen$.subscribe(e => {
        this.closeAllModals();
      });
    }


    ngAfterViewInit(): void {
       
      

      this.modalClose$ = this.modalService.onHide.pipe(
        map(() => {

          this.modalActive = false;
          return true;
        })
      );
  
      this.modalClose$.subscribe(res => {
        /* https://stackoverflow.com/questions/28643617/bootstrap-modal-makes-scrollbar-disappear-after-closing */
        document.getElementsByTagName('body')[0].classList.remove('modal-open');
        this.modalActive = false;            
      });
  
      this.modalOpen$ = this.modalService.onShow.pipe(
        map(res => {

          this.modalActive = true;
          return res;
        })
      );
    }


  closeModal(modalRef: BsModalRef){
      modalRef.hide();
      this.modalRef.hide();
  }

  // async ? instead of subscribe to let the template handle the subscription?
  acceptOffer(modalRef: BsModalRef) {

    if(this.offers){
     
      this.selected_offer = this.offers![this.currentIndex];
      this.sharedService.offer = this.offers[this.currentIndex];
      this.offerService.setOfferAccepted(this.selected_offer).pipe(
          map(() => {
            this.closeModal(modalRef)
            this.navigateToOfferConfirmation();
          })
        ).subscribe();
    }
    else{
      // WARN USER SOMETHING WENT WRONG
    }

  }

  declineOffer() {

    if(this.offers){     
      this.selected_offer = this.offers[0];
      this.sharedService.offer = this.offers[0];
      this.offerService.setOfferDeclined(this.selected_offer.prop_id, this.selected_offer.offer_alias_id)
      .pipe(
          map(() => {
            this.navigateToOfferDeclined();
          })
        ).subscribe();
    }
    else{
      // WARN USER SOMETHING WENT WRONG
    }

  }

  navigateToOfferConfirmation(){
    this.router.navigate(['/offerConfirmation'], { relativeTo: this.route });
  }

  navigateToOfferDeclined(){
    this.router.navigate(['/offerDeclined'], { relativeTo: this.route });
  }

  openModal(template: TemplateRef<any>, index: number) {
    this.getImages(index);
    this.setSelectedOffer(index); 
    this.currentIndex = index;
     this.modalRef = this.modalService.show(template, this.config
      
    );
  }

  openModalTermsAndConditions(openTemplate: TemplateRef<any>, index: number) {


    if(index != -1){
      this.currentIndex = index;
      this.setSelectedOffer(index);
    }

    if(this.modalRef){
      this.modalRef.hide();
    }

    setTimeout(() => {
      this.modalRef = this.modalService.show(openTemplate, this.config);
    }, 100)
    }

    _openModalTermsAndConditions(openTemplate: TemplateRef<any>) {

    if(this.modalRef){
      this.modalRef.hide();
    }

    setTimeout(() => {
      this.modalRef = this.modalService.show(openTemplate, this.config);
    }, 330)

  }

  closeAllModals() {  
    for (let i = 1; i <= this.modalService.getModalsCount(); i++) {
      this.modalService.hide(i);
    }
  }

  getImages(index: number){

    if(!this.offers) return;
    
    const photos = this.offers[index].amenities?.room_photo_urls;

    this.images = [];

    if(!photos) return;
    for(const photo of photos){
      this.images.push(new ImageItem({src: photo, thumb: photo}));
    }

  }

  toggleSearch(){
    this.view_more_toggle = !this.view_more_toggle;
    
    if(this.view_more_toggle === true){
      this.numberOfOffersToShow = this.offers!.length;
    }
    else{
      this.numberOfOffersToShow = 3;
    }



    setTimeout(()=>{ // this will make the execution after the above boolean has changed
      this.searchInput.nativeElement.focus();
    },0); 

  }


  clickForMoreDetails(j: number, i: number, attr: string): string{
    
    let ofr_attrs = this.offers![i].amenities.upsell_attributes.slice(0, this. max_list_index);

    let contentHeight = 0;
    let arrayHeights = [0]

    for (let k = 0; k < ofr_attrs.length; k++) {
        if(document.getElementById('content-' + i.toString() + '-' + i)){;
          contentHeight = contentHeight + document.getElementById('content-' + i.toString() + '-' + k)!.offsetHeight + 10;
        }
        arrayHeights.push(contentHeight);
    }

    let b_idx = 3;

   for (let k = 0; k < arrayHeights.length; k++) {
      if(arrayHeights[k] > 140){
        b_idx = k;
        // offer.max_list_attrs = k + 1;
        // console.log(i, j, b_idx);
        break;
      }    
   }


    let attr_string = (j==b_idx && this.currentIndex !== i) || (j==b_idx && this.currentIndex === i && this.collapsed) ? "Click for more details" + "\n" : attr.substring(attr.indexOf(':'), attr.length);

    if((j>b_idx && this.currentIndex !== i) || (j>b_idx && this.currentIndex === i && this.collapsed)){
      attr_string = '...'
    }
    
    return attr_string;
  }


  togglAttrSpan(j: number, i: number, attr: string): boolean{
    
    let ofr_attrs = this.offers![i].amenities.upsell_attributes.slice(0,this. max_list_index);

    let contentHeight = 0;
    let arrayHeights = []

    for (let k = 0; k < ofr_attrs.length; k++) {


        if(document.getElementById('content-' + i.toString() + '-' + i)){;
          contentHeight = contentHeight + document.getElementById('content-' + i.toString() + '-' + k)!.offsetHeight + 10;
        }
        arrayHeights.push(contentHeight);
    }

    let b_idx = 3;

   for (let k = 0; k < arrayHeights.length; k++) {
      if(arrayHeights[k] > 140){
        b_idx = k;
        break;
      }    
   }


    let attr_bool = true;

    if((j>b_idx && this.currentIndex !== i) || (j>b_idx && this.currentIndex === i && this.collapsed)){
      attr_bool = false;
    }
    
    return attr_bool;
  }

  toggleCollapse(index: number) {

    if(index !== this.currentIndex){
      this.collapsed = false;
    }else{
      this.collapsed = !this.collapsed;
    }

    this.currentIndex = index;

    if(document.getElementById('contentParagraph' + index.toString())){
      this.contentParagraphHeight = document.getElementById('contentParagraph' + index.toString())!.offsetHeight;
    }


    if(document.getElementById('contentLineHeight' + index.toString())){
      this.ROW_CONTENT_HEIGHT = document.getElementById('contentLineHeight' + index.toString())!.offsetHeight + 10;  
    }

    let contentHeight = 0;


    contentHeight = contentHeight + document.getElementById('content-' + index.toString())!.offsetHeight;
    this.CONTENT_HEIGHT = contentHeight;
    // console.log('contentHeight', contentHeight);
    

    this.setMaxListIndex(this.collapsed, index);
    this.setCardHeigt(index);
    this.setRequestButtonPosition(index);
    this.setSelectedOffer(index);

  }


  hiddenStyle(index: number): {[key: string]: boolean} {
    if(this.currentIndex == index && !this.collapsed)
    {
      return {'unhidden': true}
    }    
    return {'unhidden': false};
  }


  setCardHeigt(index: number) {
    if(!this.offers) return;
    this.cardHeight =  
    this.MIN_CARD_HEGIHT - 150
    + this.contentParagraphHeight 
    + this.CONTENT_HEIGHT

    ;
  }


  cardHeight: number = 290;
  MIN_CARD_HEGIHT: number = 290;
  CONTENT_HEIGHT: number = 0;
  ROW_CONTENT_HEIGHT:number = 0;

  contentParagraphHeight = 0;
  MIN_REQ_BTN_MARGIN:number = 105;
  requestButtonMargin:number = this.MIN_REQ_BTN_MARGIN;

  setRequestButtonPosition(index: number) {
    if(!this.offers) return;
    // this.requestButtonMargin = this.MIN_REQ_BTN_MARGIN + (this.offers[index].upsell_attributes.length - this.MIN_LIST_INDEX + 2) * this.ROW_CONTENT_HEIGHT * 2;
    this.requestButtonMargin = 
    this.MIN_REQ_BTN_MARGIN - 150 
    + this.contentParagraphHeight 
    + this.CONTENT_HEIGHT

    // + (this.offers[index].upsell_attributes.length - this.MIN_LIST_INDEX + 1) * this.ROW_CONTENT_HEIGHT
    // -20
    ;


  }

  setMaxListIndex(collapsed: boolean, index: number){
    if(!this.offers) return;
    this.max_list_index = this.offers[index].amenities.upsell_attributes.length;
  }

  setSelectedOffer(index: number){
    
    if(!this.offers) return;
    this.selected_offer = this.offers[index];
    this.sharedService.offer = this.selected_offer;  
  }

 

}
